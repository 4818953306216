/*
Template: Grandy
Author: Incognito Themes
Version: 1.0
Designed and Development by: Incognito Themes
*/


/*
====================================
[ CSS TABLE CONTENT ]
------------------------------------
    1.0 - General
    2.0 - Typography
    3.0 - Global Style
    4.0 - Navigation
    5.0 - Search Box
    6.0 - Page Title Section
    7.0 - Slider Section
    8.0 - Creative Section
    9.0 - Recent Project Section
    9.1 - Overlay and hover effect
    10.0 - Magnific Popup CSS
    11.0 - Feature Section
    12.0 - About Section
    13.0 - Counter Section
    14.0 - Team Section
    15.0 - Video Section
    16.0 - Service Section
    17.0 - Process Section
    18.0 - Portfolio Section
    19.0 - Portfolio Single Page
    20.0 - Partner Section
    21.0 - Case study section
    22.0 - Map Section
    23.0 - Contact Section
    24.0 - Footer Section
    25.0 - Preloader
    26.0 - Homepage Two
    27.0 - Homepage Three
    28.0 - About us Page
    29.0 - About us page two
    30.0 - Blog Page
    31.0 - Blog Page Version Two
    32.0 - Full Width Section
    33.0 - Call to action section
    34.0 - Client Testimonial Style
    35.0 - Parallax Homepage
    36.0 - Contact Page
    37.0 - 404 page
    38.0 - Slick slider customization
-------------------------------------
[ END CSS TABLE CONTENT ]
=====================================
*/

/* =============================================
            	Theme Reset Style
============================================= */
@import url('https://fonts.googleapis.com/css?family=Kaushan+Script|Playfair+Display|Poppins:400,500,600,700|Source+Sans+Pro:200,300,400,700');
@import url("./ionicons.min.css");
@import url("./custom-icons.min.css");
@import url("./cubeportfolio.css");
@import url("./bootsnav.css");
@import url("./slick.css");
@import url("./animate.css");
@import url("./responsive.css");

*:after,
*:before,
p a,
a,
.team-memberpic img,
.team-memberpic figcaption,
.postimg figcaption, .description, .pricing-row .pricing-item, .team-memberinfo, .theme-btn, .gallery-filter li, #contactForm input, #contactForm textarea, .theme-btn-white, .navbar-nav li a, #back-to-top, .client-logo img, .icon-view code, .box-shadow-hover, ul#portfolio-filter li, .product-slider .slick-prev i, .product-slider .slick-next i, .portfolio .folio-overlay, .team-member-container .member-caption, .product-wrap .product-caption{
	-webkit-transition: all 300ms ease-in-out;
	-moz-transition: all 300ms ease-in-out;
	-ms-transition: all 300ms ease-in-out;
	-o-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
}

/* Keyframes */
/*empty*/
@-webkit-keyframes empty {0% {opacity: 1}}
@-moz-keyframes empty {0% {opacity: 1}}
@keyframes empty {0% {opacity: 1}}
@-webkit-keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@-moz-keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@keyframes fadeIn {
	0% { opacity:0; }
	100% { opacity:1; }
}
@-webkit-keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@-moz-keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}
@-webkit-keyframes backSlideOut {
	25% { opacity: .5; -webkit-transform: translateZ(-500px); }
	75% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(-200%); }
}
@-moz-keyframes backSlideOut {
	25% { opacity: .5; -moz-transform: translateZ(-500px); }
	75% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; -moz-transform: translateZ(-500px) translateX(-200%); }
}
@keyframes backSlideOut {
	25% { opacity: .5; transform: translateZ(-500px); }
	75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
	100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
}
@-webkit-keyframes backSlideIn {
	0%, 25% { opacity: .5; -webkit-transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; -webkit-transform: translateZ(-500px); }
	100% { opacity: 1; -webkit-transform: translateZ(0) translateX(0); }
}
@-moz-keyframes backSlideIn {
	0%, 25% { opacity: .5; -moz-transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; -moz-transform: translateZ(-500px); }
	100% { opacity: 1; -moz-transform: translateZ(0) translateX(0); }
}
@keyframes backSlideIn {
	0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
	75% { opacity: .5; transform: translateZ(-500px); }
	100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
@-webkit-keyframes scaleToFade {to { opacity: 0; -webkit-transform: scale(.8); }}
@-moz-keyframes scaleToFade {to { opacity: 0; -moz-transform: scale(.8); }}
@keyframes scaleToFade {to { opacity: 0; transform: scale(.8); }}
@-webkit-keyframes goDown {from { -webkit-transform: translateY(-100%); }}
@-moz-keyframes goDown {from { -moz-transform: translateY(-100%); }}
@keyframes goDown {from { transform: translateY(-100%); }}
@-webkit-keyframes scaleUpFrom {from { opacity: 0; -webkit-transform: scale(1.5); }}
@-moz-keyframes scaleUpFrom {from { opacity: 0; -moz-transform: scale(1.5); }}
@keyframes scaleUpFrom {from { opacity: 0; transform: scale(1.5); }}
@-webkit-keyframes scaleUpTo {to { opacity: 0; -webkit-transform: scale(1.5); }}
@-moz-keyframes scaleUpTo {to { opacity: 0; -moz-transform: scale(1.5); }}
@keyframes scaleUpTo {to { opacity: 0; transform: scale(1.5); }}

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: translateY(-50%) scale(0);
  }
  to {
    -webkit-transform: translateY(-50%) scale(1);
  }
}
@-moz-keyframes scaleIn {
  from {
    -moz-transform: translateY(-50%) scale(0);
  }
  to {
    -moz-transform: translateY(-50%) scale(1);
  }
}
@keyframes scaleIn {
  from {
    -webkit-transform: translateY(-50%) scale(0);
    -moz-transform: translateY(-50%) scale(0);
    -ms-transform: translateY(-50%) scale(0);
    -o-transform: translateY(-50%) scale(0);
    transform: translateY(-50%) scale(0);
  }
  to {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
  }
}
@-webkit-keyframes slideIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  from {
    opacity: 0;
    -moz-transform: translateX(-25px);
  }
  to {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@keyframes slideIn {
  from {
    opacity: 0;
    -webkit-transform: translateX(-25px);
    -moz-transform: translateX(-25px);
    -ms-transform: translateX(-25px);
    -o-transform: translateX(-25px);
    transform: translateX(-25px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

#loader-overlay {
    width: 100%;
    height: 100vh;
    background: #212121;
    position: fixed;
    z-index: 999999;
}
.loader{
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  border: 4px solid #Fff;
  top: 50%;
  animation: loader 2s infinite ease;
  left: 50%;
  margin-left:-25px;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}

.arcon-pulse {
  width: 40px;
  height: 40px;
  margin: 40px auto;
  background-color: #2196F3;
  border-radius: 100%;
  -webkit-animation: arcon-pulseScaleOut 1s infinite ease-in-out;
          animation: arcon-pulseScaleOut 1s infinite ease-in-out; }

@-webkit-keyframes arcon-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }

@keyframes arcon-pulseScaleOut {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; } }


/* Gradient Animation */
@-webkit-keyframes AnimationName {
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}
@-o-keyframes AnimationName {
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}
@keyframes AnimationName { 
    0%{background-position:86% 0%}
    50%{background-position:15% 100%}
    100%{background-position:86% 0%}
}



.fade-icon {
	background: rgba(255,255,255,0.1);
}

.feature-box .fade-icon:after {
	top: -7px;
    left: -7px;
    padding: 7px;
    box-shadow: 0 0 0 4px #3949ab;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(.8);
    opacity: 0;
}

.feature-box .fade-icon-white:after {
    box-shadow: 0 0 0 4px #fff;
}

.feature-box .fade-icon-dark:after {
    box-shadow: 0 0 0 4px #212121;
}

.feature-box .fade-icon-pink:after {
    box-shadow: 0 0 0 4px #E91E63;
}

.feature-box .fade-icon-green:after {
    box-shadow: 0 0 0 4px #8BC34A;
}



.fade-icon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
	border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.feature-box .fade-icon:hover:after {
	-webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

@-webkit-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
		-webkit-transform: scale(1.5);
		opacity: 0;
	}
}
@-moz-keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
		-moz-transform: scale(1.5);
		opacity: 0;
	}
}
@keyframes sonarEffect {
	0% {
		opacity: 0.3;
	}
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
	}
	100% {
		box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px #1ec0ff, 0 0 0 10px rgba(255,255,255,0.5);
		transform: scale(1.5);
		opacity: 0;
	}
}

@-webkit-keyframes fadeTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeTop {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeTop {
  -webkit-animation-name: fadeTop;
  animation-name: fadeTop;
}

@-webkit-keyframes fadeLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeLeft {
  -webkit-animation-name: fadeLeft;
  animation-name: fadeLeft;
}

@-webkit-keyframes fadeRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeRight {
  -webkit-animation-name: fadeRight;
  animation-name: fadeRight;
}



::selection {
    background-color: #212121;
    color: #fff;
}

* {
    margin: 0;
    padding: 0;
	min-height:0;
	min-width:0;
}
*, :after, :before {
    box-sizing: border-box;
}
html,
body {
    position: relative;
    height: 100%;
}
body {
    color: #888;
    background: #fafafa;
    font-size:16px;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400;
}

.wrapper {
	position: relative;
    z-index: 20;
    overflow-x: hidden;
}
.eicon {
    display: inline-block;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.poppin-font{font-family: 'Poppins', sans-serif;}
.play-font{font-family: 'Playfair Display', serif;}
.source-font{font-family: 'Source Sans Pro', sans-serif;}
.kaushan-font{font-family: 'Kaushan Script', cursive;}

h1, h2, h3, h4, h5, h6 {
    color: #212121;
    margin: 20px 0 10px 0;
    font-style: normal;
    font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.2;
}

h1 { font-size: 48px;}
h2 { font-size: 36px;}
h3 { font-size: 24px;}
h4 { font-size: 18px;}
h5 { font-size: 16px;}
h6 { font-size: 12px;}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a { color: #fff;}
p a:hover {color: #1a237e;}
p {
    letter-spacing: normal;
    line-height: 24px;
	color: #888;
	font-weight:400;
	font-family: 'Source Sans Pro', sans-serif;
}
a {text-decoration: none;}
a:hover, a:focus, a:active {
    outline: none;
    text-decoration: none;
}
ul {
  list-style: disc;
}
li > ul,
li > ol {
  padding: 0;
  margin-left: 15px;
}
figure {
    margin-bottom: 6px;
    position: relative;
}
img {
    height: auto;
    max-width: 100%;
}
.mark, mark{background-color:#3949ab;color:#fff;}

pre {
	margin: 0 20px;
    padding: 10px;
    border: 1px solid #e2e2e2;
    background: #f1f1f1;
	border-radius:0;
}

blockquote {
    border-left: 5px solid #3949ab;
	background:#fff;
}

.blockquote-reverse, blockquote.pull-right {
    border-right: 5px solid #3949ab;
	background:#fff;
}

.outline-alert {
    background-color: transparent!important;
}

strong { color: #414141; }
hr{border:0;height: 1px;width:80px;margin:20px auto;}
hr.left-line{margin:20px 0 20px 0;}
.display-block {display:block;}
.display-inline {display:inline-block;}

textarea {
	resize:none;
}

label {
    margin-bottom: 10px;
    font-weight: 500;
	color:#212121;
	font-family: 'Poppins', sans-serif;
	font-size: 13px;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
    border-color: none;
    box-shadow: none;
    outline: 0 none;
}


section {
	padding-top:120px;
	padding-bottom:120px;
	position: relative;
	z-index: 10;
	background:#eee;
}

.section-heading {
	text-align: center;
    margin: auto;
    float: none;
    padding-bottom: 20px;
}

.section-heading h2 {
	font-weight:700;
}

.section-heading h4 {
	color:#777;
	font-size:16px;
}

.section-heading p.font-26px {
	line-height:1.5;
	color:#616161;
	font-weight:300;
}

.center-layout {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  position: relative;
}
.v-align-middle {
  display: table-cell;
  vertical-align: middle;
}

.title-section{
    position: relative;
    z-index: 10;
}

.title-section h2[data-backletter], .title-section h4[data-backletter] {
  position: relative;
}

.title-section h2[data-backletter]:before, .title-section h4[data-backletter]:before {
  z-index: -1;
  content: attr(data-backletter);
  font-size: 3em;
  opacity: .06;
  position: absolute;
  top: -0.5em;
  left: -0.15em;
  text-align: center;
  text-transform: uppercase;
}

.title-section.text-center h2[data-backletter]:before{
    left: 0;
    right: 0;
    margin: auto;
}


/*=============================================
              		Header
=============================================*/

.navbar-nav {
    float: right;
    margin: 0;
    padding: 0 10px;
}

@media (max-width: 991px) {
  nav.navbar.bootsnav.navbar-transparent .logo, .logo{text-align: center;display: inline-block !important;height: 60px !important;}
}

nav.navbar.bootsnav.navbar-transparent .logo {
	position: relative;
    z-index: 110;
	display: table;
    height: 80px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

nav.navbar.bootsnav.navbar-transparent.brand-center .logo, .brand-center .logo {
	display:block;
	max-width: 200px;
    text-align: center;
    margin: 0 auto;
}

nav.navbar.bootsnav.navbar-transparent.brand-center .navbar-brand {
    padding: 20px 15px;
}

.navbar-brand {
	padding: 11px 15px;
}

.logo {
    position: relative;
    z-index: 110;
    display: table;
    height: 60px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.logo img {
    max-height: 33px;
	-webkit-transition: all .5s ease;
    transition: all .5s ease;
    display: block;
}

nav .logo > a {
	display: table-cell;
    vertical-align: middle;
}

.navbar-scrollspy .logo img {
	text-align:center;
	margin: 0 auto;
}

nav.bootsnav.navbar-sidebar ul.nav {
	margin: 0 0 30px 0;
}

nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.nav-btn-default {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
}

nav.navbar.bootsnav ul.nav > li > a.nav-btn-default {
    padding: 10px 15px;
    margin-top: 6.5px;
    color: #fff;
}

nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-dark {
    background-color: #212121;
}

nav.navbar.bootsnav ul.nav > li > a.btn-dark {
    background-color: #212121;
}

nav.navbar.bootsnav ul.nav > li > a.btn-dark:hover, nav.navbar.bootsnav ul.nav > li > a.btn-dark:active, nav.navbar.bootsnav ul.nav > li > a.btn-dark:focus, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-dark:hover, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-dark:active, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-dark:focus {
    background-color: #000;
}

nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-color {
    background-color: #3949ab;
}

nav.navbar.bootsnav ul.nav > li > a.btn-color {
    background-color: #3949ab;
}

nav.navbar.bootsnav ul.nav > li > a.btn-color:hover, nav.navbar.bootsnav ul.nav > li > a.btn-color:active, nav.navbar.bootsnav ul.nav > li > a.btn-color:focus, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-color:hover, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-color:active, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-color:focus {
    background-color: #283593;
}

nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-light {
    background-color: #eee;
	color: #212121;
}

nav.navbar.bootsnav ul.nav > li > a.btn-light {
    background-color: #eee;
	color: #212121;
}

nav.navbar.bootsnav ul.nav > li > a.btn-light:hover, nav.navbar.bootsnav ul.nav > li > a.btn-light:active, nav.navbar.bootsnav ul.nav > li > a.btn-light:focus, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-light:hover, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-light:active, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-light:focus {
    background-color: #e0e0e0;
}

nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-outline {
    background-color: transparent;
	color: #fff;
	border: 2px solid #fff;
}

nav.navbar.bootsnav ul.nav > li > a.btn-outline {
    background-color: transparent;
	color: #212121;
	border: 2px solid #212121;
}

nav.navbar.bootsnav ul.nav > li > a.btn-outline:hover, nav.navbar.bootsnav ul.nav > li > a.btn-outline:active, nav.navbar.bootsnav ul.nav > li > a.btn-outline:focus, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-outline:hover, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-outline:active, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a.btn-outline:focus  {
    background-color: #eee;
	border: 2px solid #eee;
	color:#212121;
}

.dropdown > .dropdown-menu > li {
  position: relative;
}

@media (min-width: 993px) {
  .dropdown > .dropdown-menu, 
.dropdown > .dropdown-menu > li > .dropdown-menu {
  opacity:0;
  display: block;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

.dropdown:hover > .dropdown-menu,
.dropdown .dropdown-menu > li:hover > .dropdown-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
}

@media (max-width: 992px) {
  /* .dropdown:hover > .dropdown-menu,
  .dropdown .dropdown-menu > li:hover > .dropdown-menu {
    visibility: hidden;
    opacity: 0;
  }
  */
  .dropdown > .dropdown-menu {
  display: none;
} 
.dropdown > .dropdown-menu, 
.dropdown > .dropdown-menu > li > .dropdown-menu {
  visibility: visible;
}

nav.navbar.bootsnav .collapse.navbar-collapse.collapse-navbar-exit-done#navbar-menu {display: block !important;}
nav.navbar.bootsnav .collapse.navbar-collapse.collapse-navbar-exit-active {max-height: 350px !important;display: block !important;}
nav.navbar.bootsnav .collapse.navbar-collapse.collapse-navbar-exit-done {max-height: 0; transition: max-height 0.3s cubic-bezier(0, 0.55, 0.45, 1);}
nav.navbar.bootsnav .collapse.navbar-collapse.collapse-navbar-enter {display: block !important; }
nav.navbar.bootsnav .collapse.navbar-collapse.collapse-navbar-enter-active {max-height: 0px !important;}
nav.navbar.bootsnav .collapse.navbar-collapse.collapse-navbar-enter-done {display: block !important; max-height: 350px; transition: max-height 0.3s cubic-bezier(0, 0.55, 0.45, 1);}

nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > ul.dropdown-menu--enter {display: block; height:0}
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > ul.dropdown-menu--enter-active {display: block; min-height:100px; opacity: 0; }
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > ul.dropdown-menu--enter-done { display: block; min-height:100px; opacity: 1; transition: opacity 0.3s cubic-bezier(0, 0.55, 0.45, 1);}
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > ul.dropdown-menu--exit-active {display: block; min-height:100px; opacity: 0; }
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > ul.dropdown-menu--exit-done { height:0; opacity: 0; transition: opacity 0.3s cubic-bezier(0, 0.55, 0.45, 1);}

nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > .dropdown-menu > li > .row .col-menu--enter .content {display: block; height:0}
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > .dropdown-menu > li > .row .col-menu--enter-active .content {display: block; min-height:100px; opacity: 0; }
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > .dropdown-menu > li > .row .col-menu--enter-done .content { display: block; min-height:100px; opacity: 1; transition: opacity 0.3s cubic-bezier(0, 0.55, 0.45, 1);}
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > .dropdown-menu > li > .row .col-menu--exit-active .content {display: block; min-height:100px; opacity: 0; }
nav.navbar.bootsnav .collapse.navbar-collapse .dropdown > .dropdown-menu > li > .row .col-menu--exit-done .content { height:0; opacity: 0; transition: opacity 0.3s cubic-bezier(0, 0.55, 0.45, 1);}

.attr-nav > ul > li.dropdown > ul.dropdown-menu.cart-list--exit-active {display:block; opacity: 1;}
.attr-nav > ul > li.dropdown > ul.dropdown-menu.cart-list--exit-done {display:block; opacity: 0;transition: opacity 0.3s ease-in-out; visibility: hidden;}
.attr-nav > ul > li.dropdown > ul.dropdown-menu.cart-list--enter-active {display:block; opacity: 0; min-height: 50px;}
.attr-nav > ul > li.dropdown > ul.dropdown-menu.cart-list--enter-done { display: block; opacity: 1; min-height: 50px; transition: all 0.3s cubic-bezier(0.215, 0.610, 0.355, 1);}

}

/*=============================================
			Sliders and Hero Section
=============================================*/
.type-it-resume > div:last-child {
  background-color: #fff !important;
  width:10px !important;
}
.hero-text{
	display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 100%;
    z-index: 10;
    color: #fff;
}
.hero-text h3, .hero-text h1{color:#3949ab;}

.hero-text h1 {
	font-size:78px;
	letter-spacing:-2px;
	line-height: 1.25;
}

.hero-text h1.letter-spacing-10{
	letter-spacing:10px;
}

.hero-text h1.letter-spacing-0{
	letter-spacing:0px;
}

.hero-text-wrap .hero-text h1.font-90px {
	font-size:90px;
}

.hero-text h2 {
	font-size:62px;
}

.hero-text h3 {
	font-size:49px;
}

.hero-text h4 {
	font-size:30px;
}

.hero-text h5 {
	font-size:19px;
}

.hero-text h6 {
	font-size:14px;
}


.dark-bg{background-color:#212121;}
.darken-bg{background-color:#151515;}
.d-dark-bg{background-color:#0a0a0a;}
.white-bg{background-color: #fff;}
.default-bg{background-color:#3949ab;}
.red-bg{background-color:#cb2d3e;}
.grey-bg{background-color:#eee;}
.orange-bg{background-color:#ffab40;}
.green-bg{background-color:#8BC34A;}

.pattern-bg-dark{background-image: url(../images/dark-pattern.png);background-repeat: repeat;}
.pattern-bg-light{background-image: url(../images/light-pattern.png);background-repeat: repeat;}
.light-br-r{border-right:1px solid #eeeeee;}

.fixed-bg{background-attachment: fixed!important;}

.overlay-bg {
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    position: absolute;
	z-index: -1;
	top:0;
	left:0;
}

.hero-text-wrap.overlay-bg {
	background: rgba(0, 0, 0, 0.75);
	width: 100%;
    height: 101vh;
    position: absolute;
	z-index: 1;
}

.hero-text-wrap.color-overlay-bg {
	background: rgba(57,73,171,0.82);
	width: 100%;
    height: 101vh;
    position: absolute;
	z-index: 1;
}

.hero-text-wrap.white-overlay-bg {
	background: rgba(225,225,225,0.82);
	width: 100%;
    height: 101vh;
    position: absolute;
	z-index: 1;
}


.hero-text-wrap.light-overlay-bg {
	background-color: #cb2d3e;
	width: 100%;
    height: 101vh;
    position: absolute;
	z-index: 1;
}

.overlay-bg-dark {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
	z-index: -1;
	top:0;
	left:0;
}

.overlay-bg-light {
    background: rgba(255,255,255,0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

section.dark-block .hero-text-wrap, section.dark-block.title-hero-bg::before {
	background-color:transparent;
}

.parallax-overlay {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
	z-index: -1;
	top:0;
	left:0;
}

.hero-text-wrap.gradient-overlay{
    position: absolute;
	z-index: 1;
	background: rgba(255,127,95,0.6);
    background: -moz-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,127,95,0.6)), color-stop(100%, rgba(254,179,123,0.6)));
    background: -webkit-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
    background: -o-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
    background: -ms-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
    background: linear-gradient(to bottom, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f5f', endColorstr='#feb37b', GradientType=0 );
}

.hero-text-wrap h1.font-100px{font-size:100px;}

.bg-video .hero-text-wrap.gradient-overlay {
	height:101vh;
}

.gradient-overlay {
	background: rgba(255,127,95,0.6);
background: -moz-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,127,95,0.6)), color-stop(100%, rgba(254,179,123,0.6)));
background: -webkit-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
background: -o-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
background: -ms-linear-gradient(top, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
background: linear-gradient(to bottom, rgba(255,127,95,0.6) 0%, rgba(254,179,123,0.6) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f5f', endColorstr='#feb37b', GradientType=0 );
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.parallax-bg {
    background-size: cover;
        -webkit-background-size: cover;
     -moz-background-size: cover;
-o-background-size: cover;
	background-repeat:no-repeat;
	background-position: 50% 50%;
	width:100%;
	height:100%;
}

.box-shadow {box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);}

.box-shadow-hover:hover{box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);}
.member-socialicons{
    overflow: hidden;
    list-style: none;
    text-align:center;
    line-height: 20px;
}
.member-socialicons li{
    float: left;
    padding: 0 10px;
    line-height: inherit;
    list-style-type: none;
}
.member-socialicons li a{
    color: #fff;
    display:block;
}
.member-socialicons li a:hover {
    color: #ff4552;
}
.member-socialicons li a i{
    display:block;
    font-size:13px;
    text-align:center;
}
.row.no-gutters{
    margin-right: 0;
    margin-left: 0;
}
.no-bottom-padding{
    padding-bottom: 0;
}
.no-gutters > [class^="col-"],
.no-gutters > [class*="col-"],
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*="col-"]{
    padding-right: 0;
    padding-left: 0;
}
.no-top-padding{
    padding-top: 0;
}


/*=============================================
                Widgets
=============================================*/

::-webkit-input-placeholder{color:#212121;}
::-moz-placeholder{color:#212121;}
:-ms-input-placeholder{color:#212121;}
:-moz-placeholder{color:#212121;}

input.search-bar-top::-webkit-input-placeholder { color: #fff!important; }
input.search-bar-top::-moz-placeholder { color: #fff!important; }
input.search-bar-top:-ms-input-placeholder { color: #fff!important; }
input.search-bar-top:-moz-placeholder { color: #fff!important; }

.relative{position:relative;}
.absolute{position:absolute;}

.upper-case{text-transform:uppercase;}
.lower-case{text-transform:lowercase;}
.capitalize{text-transform:capitalize;}
.text-left{text-align:left;}

.hero-text-wrap .hero-text .type-it {
	font-size: 78px;
    letter-spacing: -2px;
    line-height: 1.25;
}

[data-width="100"]{width:100%;}
[data-width="90"]{width:90%;}
[data-width="80"]{width:80%;}
[data-width="70"]{width:70%;}
[data-width="60"]{width:60%;}
[data-width="50"]{width:50%;}
[data-width="40"]{width:40%;}
[data-width="30"]{width:30%;}
[data-width="20"]{width:20%;}
[data-width="10"]{width:10%;}
[data-width="0"]{width:0;}

.font-100 {
	font-weight:100;
}
.font-200 {
	font-weight:200;
}
.font-300 {
	font-weight:300;
}
.font-400 {
	font-weight:400;
}

.font-500 {
	font-weight:500;
}

.font-600 {
	font-weight:600;
}

.font-700 {
	font-weight:700;
}
.font-900 {
	font-weight:900;
}
.font-12px {
	font-size: 12px;
}
.font-14px {
	font-size: 14px;
}
.font-16px {
	font-size: 16px;
}
.font-18px {
	font-size: 18px;
}
.font-20px {
	font-size: 20px;
}
.font-26px {
	font-size: 26px;
}
.font-30px {
	font-size: 30px;
}
.font-35px {
	font-size: 35px;
}
.font-40px {
	font-size: 40px;
}
.font-50px {
	font-size: 50px;
}
.font-60px {
	font-size: 60px;
}
.font-70px {
	font-size: 70px;
}
.font-80px {
	font-size: 80px;
}
.font-90px {
	font-size: 90px;
}
.font-100px {
	font-size: 100px;
}
.font-120px {
	font-size: 120px;
}
.font-130px {
	font-size: 130px;
}
.font-italic {
	font-style: italic;
}
.font-light {
	font-weight:400;
}
.font-bold {
	font-weight:bold;
}
a:hover, a:focus {
	color: #1a237e;
}
.default-color {
	color: #3949ab;
}
.secondary-color {
	color: #1a237e;
}
.white-color {
	color: #fff !important;
}
.white-color p, .white-color h1, .white-color h2, .white-color h3, .white-color h4, .white-color h5, .white-color h6 {
    color: #fff!important;
}
.dark-color {
	color: #212121!important;
}
.dark-color p, .dark-color h1, .dark-color h2, .dark-color h3, .dark-color h4, .dark-color h5, .dark-color h6 {
    color: #212121!important;
}

.orange-color {
	color:#ffab40;
}

.red-color {
	color: #cb2d3e;
}

.pink-color {color:#ec407a;}

.line-height-24 {
	line-height: 24px;
}
.line-height-22 {
	line-height: 22px;
}
.line-height-26 {
	line-height: 26px;
}
.line-height-30 {
	line-height: 30px;
}
.line-height-35 {
	line-height: 35px;
}
.line-height-40 {
	line-height: 40px;
}
.line-height-45 {
	line-height: 45px;
}
.line-height-50 {
	line-height: 50px;
}
.letter-spacing-0 {
	letter-spacing: 0px;
}
.letter-spacing-5 {
	letter-spacing: 5px;
}
.letter-spacing-10 {
	letter-spacing: 10px;
}
.letter-spacing-15 {
	letter-spacing: 15px
}
.letter-spacing-20 {
	letter-spacing: 20px;
}
.letter-spacing-25 {
	letter-spacing: 25px;
}
.mt-minus-10 {
	margin-top: -10px;
}
.mt-minus-20 {
	margin-top: -20px;
}
.mt-minus-30 {
	margin-top: -30px;
}
.mt-minus-40 {
	margin-top: -40px;
}
.mt-minus-50 {
	margin-top: -50px;
}
.mt-0 {
	margin-top: 0px;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mt-20 {
	margin-top: 20px;
}
.mt-30 {
	margin-top: 30px;
}
.mt-40 {
	margin-top: 40px;
}
.mt-50 {
	margin-top: 50px;
}
.mt-60 {
	margin-top: 60px;
}
.mt-70 {
	margin-top: 70px;
}
.mt-80 {
	margin-top: 80px;
}
.mt-90 {
	margin-top: 90px;
}
.mt-100 {
	margin-top: 100px;
}
.mt-110 {
	margin-top: 110px;
}
.mt-120 {
	margin-top: 120px;
}
.mt-130 {
	margin-top: 130px;
}
.mt-135 {
	margin-top: 135px;
}
.mt-140 {
	margin-top: 140px;
}
.mt-150 {
	margin-top: 150px;
}
.ml-0 {
	margin-left: 0px!important;
}
.ml-10 {
	margin-left: 10px;
}
.ml-15 {
	margin-left: 15px;
}
.ml-20 {
	margin-left: 20px;
}
.ml-30 {
	margin-left: 30px;
}
.ml-40 {
	margin-left: 40px;
}
.ml-50 {
	margin-left: 50px;
}
.ml-60 {
	margin-left: 60px;
}
.ml-70 {
	margin-left: 70px;
}
.ml-80 {
	margin-left: 80px;
}
.mb-0 {
	margin-bottom: 0px;
}
.mb-5 {
	margin-bottom: 5px;
}
.mb-10 {
	margin-bottom: 10px;
}
.mb-20 {
	margin-bottom: 20px;
}
.mb-30 {
	margin-bottom: 30px;
}
.mb-40 {
	margin-bottom: 40px;
}
.mb-50 {
	margin-bottom: 50px;
}
.mb-60 {
	margin-bottom: 60px;
}
.mb-70 {
	margin-bottom: 70px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-90 {
	margin-bottom: 90px;
}
.mb-100 {
	margin-bottom: 100px;
}
.mb-110 {
	margin-bottom: 110px;
}
.mb-120 {
	margin-bottom: 120px;
}

.p-0 {
	padding: 0;
}


.pt-0 {
	padding-top: 0;
}
.pt-10 {
	padding-top: 10px;
}
.pt-20 {
	padding-top: 20px;
}
.pt-30 {
	padding-top: 30px;
}
.pt-40 {
	padding-top: 40px;
}
.pt-50 {
	padding-top: 50px;
}
.pt-60 {
	padding-top: 60px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-80 {
	padding-top: 80px;
}
.pt-90 {
	padding-top: 90px;
}
.pt-100 {
	padding-top: 100px;
}
.pt-105 {
	padding-top: 105px;
}
.pt-106 {
	padding-top: 106px;
}

.pt-110 {
	padding-top: 110px;
}
.pt-120 {
	padding-top: 120px;
}
.pt-130 {
	padding-top: 130px;
}
.pt-140 {
	padding-top: 140px;
}
.pt-150 {
	padding-top: 150px;
}
.pb-0 {
	padding-bottom: 0;
}
.pb-10 {
	padding-bottom: 10px;
}
.pb-20 {
	padding-bottom: 20px;
}
.pb-30 {
	padding-bottom: 30px;
}
.pb-40 {
	padding-bottom: 40px;
}
.pb-50 {
	padding-bottom: 50px;
}
.pb-60 {
	padding-bottom: 60px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-80 {
	padding-bottom: 80px;
}
.pb-90 {
	padding-bottom: 90px;
}
.pb-100 {
	padding-bottom: 100px;
}
.pb-105 {
	padding-bottom: 105px;
}
.pb-106 {
	padding-bottom: 106px;
}
.pb-110 {
	padding-bottom: 110px;
}
.pb-120 {
	padding-bottom: 120px;
}
.pb-140 {
	padding-bottom: 140px;
}
.pl-0 {
	padding-left: 0;
}
.pl-10 {
	padding-left: 10px;
}
.pl-20 {
	padding-left: 20px;
}
.pl-30 {
	padding-left: 30px;
}
.pl-40 {
	padding-left: 40px;
}
.pl-50 {
	padding-left: 50px;
}
.pl-60 {
	padding-left: 60px;
}
.pl-70 {
	padding-left: 70px;
}
.pl-80 {
	padding-left: 80px;
}
.pl-90 {
	padding-left: 90px;
}
.pl-100 {
	padding-left: 100px;
}
.pl-110 {
	padding-left: 110px;
}
.pl-120 {
	padding-left: 120px;
}
.pr-0 {
	padding-right: 0;
}
.pr-10 {
	padding-right: 10px;
}
.pr-20 {
	padding-right: 20px;
}
.pr-30 {
	padding-right: 30px;
}
.pr-40 {
	padding-right: 40px;
}
.pr-50 {
	padding-right: 50px;
}
.pr-60 {
	padding-right: 60px;
}
.pr-70 {
	padding-right: 70px;
}
.pr-80 {
	padding-right: 80px;
}
.pr-90 {
	padding-right: 90px;
}
.pr-100 {
	padding-right: 100px;
}
.pr-110 {
	padding-right: 110px;
}
.pr-120 {
	padding-right: 120px;
}

.border-radius-25 {
	border-radius:25px;
}
.remove-padding {
	padding: 0!important;
}

.overflow-h {
  overflow: hidden;
}

.remove-margin {
	margin: 0!important;
}
.img-responsive, .full-width{width:100%;}
 

.btn,
.form-control,
.form-control:hover,
.form-control:focus,
.navbar-custom .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;
}

button:focus {
    outline: none !important;
}

.btn {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 700;
    color: #35383c;
    background-color: transparent;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 1px;
    padding: 8px 10px 7px 10px;
    margin: 5px 10px;
    border: 2px solid rgba(35, 35, 35, 0.1);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -o-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
	border-radius:4px;
}

.btn + .btn {
    margin-left: 10px;
}

.btn:focus, .btn:focus:active {
    outline: none;
}

.btn-circle {
	border-radius:100px;
}

.btn-rounded {
	border-radius:10px;
}

.btn-square {
	border-radius:0px;
}

.btn:active, .btn.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn.btn-lg {
    padding: 15px 50px;
    font-size: 12px;
}

.btn.btn-md{
    padding: 15px 35px;
    font-size: 11px;
}

.btn.btn-sm {
	padding: 16px 24px;
    font-size: 9px;
}

.btn.btn-xs {
	padding: 15px 14px;
    font-size: 7px;
}

.btn.btn-fullwidth {
	width:100%;
	min-width:300px;
}

.btn.font-300{
	font-weight:300;
}

.btn.font-400{
	font-weight:400;
}

.btn.font-500{
	font-weight:500;
}


.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    opacity: 1;
}

.has-error .form-control:focus {
    border-color: #843534;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-color {
    color: #fff;
    border-color: #3949ab;
    background-color: #3949ab;
}

.btn-color:hover, .btn-color:active, .btn-color:focus {
    background-color: #283593;
    border-color: #283593;
    color: #fff;
}

.btn-outline-color {
    color: #3949ab;
    border:2px solid #3949ab;
    background-color: transparent;
}

.btn-outline-color:hover, .btn-outline-color:active, .btn-outline-color:focus {
    color: #fff;
    border: 2px solid #3949ab;
    background-color: #3949ab;
}

.btn-outline {
    color: #212121;
    border:2px solid #e0e0e0;
    background-color: transparent;
}

.btn-outline:hover, .btn-outline:active, .btn-outline:focus {
    color: #212121;
    border: 2px solid #212121;
    background-color: transparent;
}


.btn-light {
    color: #212121;
    border-color: #eeeeee;
    background-color: #eeeeee;
}

.btn-light:hover, .btn-light:active, .btn-light:focus {
    color: #212121;
    border-color: #e0e0e0;
    background-color: #e0e0e0;
}

.btn-outline-white {
    color: #fff;
    border-color: #fff;
    background-color: transparent;
}

.btn-outline-white:hover, .btn-outline-white:active, .btn-outline-white:focus {
    color: #212121;
    border-color: #fff;
    background-color: #fff;
}

.btn-dark {
    color: #fff;
    border-color: #212121;
    background-color: #212121;
}

.btn-dark:hover, .btn-dark:active, .btn-dark:focus {
    color: #fff;
    border-color: #000;
    background-color: #000;
}


.btn-dark-outline {
    color: #212121;
    border-color: #212121;
    background-color: transparent;
}

.btn-dark-outline:hover, .btn-dark-outline:active, .btn-dark-outline:focus {
    color: #fff;
    border-color: #212121;
    background-color: #212121;
}

.btn-orange {
    color: #fff;
    border-color: #ffab40;
    background-color: #ffab40;
}

.btn-orange:hover, .btn-orange:active, .btn-orange:focus {
    color: #fff;
    border-color: #ff9100;
    background-color: #ff9100;
}

.btn-red {
    color: #fff;
    border-color: #cb2d3e;
    background-color: #cb2d3e;
}

.btn-red:hover, .btn-red:active, .btn-red:focus {
    color: #fff;
    border-color: #ad1b2b;
    background-color: #ad1b2b;
}


.btn-animate {
  text-align: center;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
}
.btn-animate  span {
  display: inline-block;
  position: relative;
  padding: 0 8.5px 0 8.5px;
  transition: padding 0.2s;
}
.btn-animate i {
  position: absolute;
  top: 50%;
  right: 0px;
  opacity: 0;
  margin-top: -6px;
  transition: opacity 0.2s, right 0.2s;
}
.btn-animate:hover span {
  padding: 0 17px 0 0;
}
.btn-animate:hover i {
  transition: opacity 0.2s, right 0.2s;
  opacity: 1;
  top: 50%;
  right: 0;
}


.icon {
	margin-left:6px;
}

.feature-box, .feature-box-right {
	float:left;
}

.feature-box i {
	margin-bottom:20px;
	display: inline-block;
}

.indigo-icon {
	color: #3949ab;
}

.dark-icon {
	color: #212121;
}

.light-icon {
	color: #fff;
}

.red-icon{
	color:#cb2d3e;
}

.blue-bg-icon {
    background-color: #3949ab;
    border-color: #3949ab;
}

.box-icon {
	margin-top:25px;
	margin-bottom:25px;
	position:relative;
	background-color:#fff;
	padding:40px 30px;
	 -webkit-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
    -moz-box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
    box-shadow: 0 7px 20px -10px rgba(171, 171, 171, 0.7);
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.box-icon:hover {
	    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
    -moz-box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
    box-shadow: 0 10px 50px -12px rgba(171, 171, 171, 0.9);
}

.box-icon h6 {
	font-size:13px;
}

.box-icon i {
	margin-bottom:25px;
}

.white-bg-icon {
    background-color: #fff;
    border-color: #fff;
}

.white-bg-icon:hover {
    background-color: #3949ab;
    border-color: #3949ab;
	color:#fff;
}

.border-bg-dark-icon {
	border:2px solid #212121;
}

.border-bg-dark-icon:hover {
    background-color: #3949ab;
    border-color: #3949ab;
    color: #fff;
}

.border-bottom {
	border-bottom: 1px solid #eee;
}

.circle-icon{
    position: relative;
    width: 85px;
    height: 85px;
    line-height: 85px !important;
    border-radius: 100%;
    text-align: center;
    display: inline-block !important;
}

.gradient-bg-icon {
    background: rgba(233,30,98,1);
	background: -moz-linear-gradient(-45deg, rgba(233,30,98,1) 0%, rgba(3,168,244,1) 100%);
	background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(233,30,98,1)), color-stop(100%, rgba(3,168,244,1)));
	background: -webkit-linear-gradient(-45deg, rgba(233,30,98,1) 0%, rgba(3,168,244,1) 100%);
	background: -o-linear-gradient(-45deg, rgba(233,30,98,1) 0%, rgba(3,168,244,1) 100%);
	background: -ms-linear-gradient(-45deg, rgba(233,30,98,1) 0%, rgba(3,168,244,1) 100%);
	background: linear-gradient(135deg, rgba(233,30,98,1) 0%, rgba(3,168,244,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e91e62', endColorstr='#03a8f4', GradientType=1 );
}

section.dark-block .team-member-container .member-caption, section.dark-block .portfolio .folio-overlay {
	background-color: rgba(0, 0, 0, 0.8);
}

section.dark-block .team-slider .slick-dots li.slick-active button, section.dark-block .blog-slider .slick-dots li.slick-active button,  section.dark-block .portfolio-slider .slick-dots li.slick-active button {
	background-color:#212121;
}


footer.dark-block .footer-main, section.dark-block .progress {
	background-color: #212121;
}

footer.dark-block .footer-copyright {
	background-color:#151515;
}

footer.dark-block .footer .widget-links li, footer.dark-block .widget-title, section.dark-block .progress-bar-title, .progress-bar, section.dark-block .progress-bar span  {
	color:#fff;
}

footer.dark-block .widget-title:before {
	border-top: 1px solid #fff;
}

section.dark-block .progress-bar {
	background-color:#fff;
}


section.gradient-block .team-slider .slick-dots li.slick-active button {
	background-color:#212121;
}

section.gradient-block .portfolio .folio-overlay, section.gradient-block .team-member-container .member-caption {
	background: rgba(255,127,95,0.8);
    background: -moz-linear-gradient(top, rgba(255,127,95,0.8) 0%, rgba(254,179,123,0.8) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,127,95,0.8)), color-stop(100%, rgba(254,179,123,0.8)));
    background: -webkit-linear-gradient(top, rgba(255,127,95,0.8) 0%, rgba(254,179,123,0.8) 100%);
    background: -o-linear-gradient(top, rgba(255,127,95,0.8) 0%, rgba(254,179,123,0.8) 100%);
    background: -ms-linear-gradient(top, rgba(255,127,95,0.8) 0%, rgba(254,179,123,0.8) 100%);
    background: linear-gradient(to bottom, rgba(255,127,95,0.8) 0%, rgba(254,179,123,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7f5f', endColorstr='#feb37b', GradientType=0 );	
}

section.gradient-block .cbp-l-filters-alignCenter .cbp-filter-item.cbp-filter-item-active {
	color:#fff;
	border-color: #fff;
}

section.gradient-block .cbp-l-filters-alignCenter .cbp-filter-item:hover {
	color:#fff;
}

footer.creative-block .footer-copyright {
	background-color:#151515;
	padding-top: 120px;
    padding-bottom: 120px;
}

footer.creative-block .footer-copyright ul.social-media li {
    margin-right: 35px;
}

footer.creative-block .footer-copyright ul.social-media {
	float:none;
}

footer.creative-block .footer-copyright ul.social-media li a {
	color:#fff;
	font-size:30px;
}

footer.creative-block .footer-copyright ul.social-media li a:hover {
	color:#616161;
}






.feature-box .pull-left {
    width: 15%;
}

.feature-box .pull-right {
    width: 80%;
}

.feature-box-right .pull-left {
    width: 80%;
}

.feature-box-right .pull-right {
    width: 15%;
}

.box {
	padding:60px;
	position:relative;
	transition: all 0.2s ease;
}

.box h2 {
    font-size: 32px;
}

.box h4 {
    font-size: 28px;
}

.box img {
	margin:0 auto 20px auto;
	display:block;
	width:200px;
}

.bordered {
	border:1px solid #eee;
}


/*=============================================
                        Counter
=============================================*/
.counter{margin:25px 0;text-align:center;}
.counter h2{
    margin:15px 0 20px;
    font-size:35px;
    line-height:50px;
    text-align: center;
}
.counter h3{
    margin:0;
    font-size:16px;
    line-height:18px;
    text-align: center;
	color:#f1f1f1;
	font-family: 'Poppins', sans-serif;
}
/*=============================================
                        Portfolio
=============================================*/

/* Portfolio overlay default
/* ------------------------------ */
.portfolio .folio-overlay {
    background-color: rgba(57, 73, 171, 0.9);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
	visibility:hidden;
}
.portfolio:hover .folio-overlay{
    opacity: 1;
	visibility:visible;
}
.portfolio-wrap {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -75px;
}
.portfolio-description .portfolio-title,
.portfolio-description .links {
    position: relative;
    left: 0;
    color: #fff;
    width: 100%;
    opacity: 0;
    visibility: hidden;
}
.portfolio-description .portfolio-title {
    top: 0;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
	font-size: 18px;
    letter-spacing: 1px;
    font-weight: 500;
}
.portfolio:hover .portfolio-description .portfolio-title {
    top: 30px;
    opacity: 1;
    visibility: visible;
}
.portfolio-description .links {
    top: 0px;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
	text-transform: uppercase;
	font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
}
.portfolio:hover .portfolio-description .links {
    top: 25px;
    opacity: 1;
    visibility: visible;
}
.portfolio-description .btn {
    background-color: transparent;;
    border-color: #fff;
    padding: 12px 22px;
    border-radius: 20px;
}
.portfolio-description .btn:hover {
    background-color: #fff;;
    border-color: #fff;
    color: #ff2a40;
}
.portfolio-container .btn.view-more {
    margin-top: 40px;
}


.photo-gallery .portfolio-wrap {
    margin-top: -60px;
}

.portfolio-details {
    position: relative;
    left: 0;
    top: 35px;
    width: 100%;
}
.portfolio-details li {
    display: inline-block;
    position: relative;
    bottom: -40px;
    opacity: 0;
    visibility: hidden;
    transform: translateZ(0);
	margin-right: 5px;
}
.portfolio-details li:last-child {
	margin-right:0;
}
.portfolio:hover .portfolio-details li {
    bottom: 0;
    opacity: 1;
    visibility: visible;
}
.portfolio:hover .portfolio-details li:nth-child(1) {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.portfolio:hover .portfolio-details li:nth-child(2){
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}
.portfolio-details li a{
    display: block;
	color:#f1f1f1;
}

.portfolio-details li a:hover{
    display: block;
	color:#3949ab;
}

#grandy-folio .portf-item.with-spacing {
  padding: 10px;  
}

#grandy-folio .portf-item {
  margin:0;
  padding: 0;
}
#grandy-folio.masonry-layout .portf-wrapper .portf-item.with-spacing .portfolio,
#grandy-folio.grid-layout .portf-wrapper .portf-item.with-spacing .portfolio,
#grandy-folio.grid-layout-two .portf-wrapper .portf-item.with-spacing .portfolio,
#grandy-folio.grid-layout-three .portf-wrapper .portf-item.with-spacing .portfolio,
#grandy-folio.masonry-layout-three .portf-wrapper .portf-item.with-spacing .portfolio {
  position: relative;
}

#grandy-folio.mosaic-layout .portf-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
#grandy-folio.mosaic-layout .portf-wrapper .large {
  grid-column: span 8;
  grid-row: span 4;
}
#grandy-folio.mosaic-layout .portf-wrapper .large img {
  height:100%;
  width:100%;
}

#grandy-folio.mosaic-layout .portf-wrapper .medium {
  grid-column: span 4;
  grid-row: span 2;
}
#grandy-folio.mosaic-layout .portf-wrapper .medium-thin {
  grid-column: span 3;
  grid-row: span 2;
}
#grandy-folio.mosaic-layout .portf-wrapper .large-thin {
  grid-column: span 7;
  grid-row: span 2;
}

#grandy-folio.mosaic-layout .portf-wrapper .medium-fat {
  grid-column: span 5;
  grid-row: span 2;
}

#grandy-folio.mosaic-layout .portf-wrapper .tall {
  grid-column: span 4;
  grid-row: span 4;
}
#grandy-folio.mosaic-layout .portfolio.gallery-image-hover.text-center {
  position: relative;
}

#grandy-folio.mosaic-layout .portf-wrapper .portf-item-off {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

#grandy-folio .portf-item .portfolio-title > a,
#team .team-member-container .member-title > a,
.team-slider .team-member-container .member-title > a {
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;
}

#grandy-folio .portf-item .portfolio-title > a:hover,
#team .team-member-container .member-title > a:hover,
.team-slider .team-member-container .member-title > a:hover {
  color:#fff;
  opacity: 1;
}

#team-dark .col-md-3 .member-title > a,
.model-slider .item-model h4 > a {
  opacity: 1;
  color: #212121;
}
#team-dark .col-md-3 .member-title > a:hover,
.model-slider .item-model h4 > a:hover {
  opacity: .9;
  color: #212121;
}

.product .product-detail h4 > a {
  color: #212121;
}
@media only screen and (max-width: 767px) {
  #grandy-folio.mosaic-layout .portf-wrapper .medium, 
  #grandy-folio.mosaic-layout .portf-wrapper .medium-thin,
  #grandy-folio.mosaic-layout .portf-wrapper .medium-fat,
  #grandy-folio.mosaic-layout .portf-wrapper .large-thin,
  #grandy-folio.mosaic-layout .portf-wrapper .large, 
  #grandy-folio.mosaic-layout .portf-wrapper .tall {
    grid-column: span 12;
  }
}

#grandy-folio.mosaic-gallery .gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
#grandy-folio.mosaic-gallery .portf-wrapper .large img {
  height:100%;
  width:100%;
}
#grandy-folio.mosaic-gallery .gallery-wrapper .large {
  grid-column: span 8;
  grid-row: span 1;
}
#grandy-folio.mosaic-gallery .gallery-wrapper .medium {
  grid-column: span 4;
  grid-row: span 1;
}
#grandy-folio.mosaic-gallery .gallery-wrapper .medium-thin {
  grid-column: span 3;
  grid-row: span 2;
}
#grandy-folio.mosaic-gallery .gallery-wrapper .large-thin {
  grid-column: span 7;
  grid-row: span 2;
}

#grandy-folio.mosaic-gallery .gallery-wrapper .medium-fat {
  grid-column: span 5;
  grid-row: span 2;
}

#grandy-folio.mosaic-gallery .gallery-wrapper .tall {
  grid-column: span 4;
  grid-row: span 4;
}
#grandy-folio.mosaic-gallery .portfolio.gallery-image-hover.text-center {
  position: relative;
}

@media only screen and (max-width: 767px) {
  #grandy-folio.mosaic-layout .portf-wrapper .medium, 
  #grandy-folio.mosaic-layout .portf-wrapper .medium-thin,
  #grandy-folio.mosaic-layout .portf-wrapper .medium-fat,
  #grandy-folio.mosaic-layout .portf-wrapper .large-thin,
  #grandy-folio.mosaic-layout .portf-wrapper .large, 
  #grandy-folio.mosaic-layout .portf-wrapper .tall {
    grid-column: span 12;
  }
}


/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.9;
  filter: alpha(opacity=90); }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
  box-shadow: none; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  filter: alpha(opacity=65);
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  filter: alpha(opacity=65);
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100); }
  .mfp-arrow:before,
  .mfp-arrow:after,
  .mfp-arrow .mfp-b,
  .mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after,
  .mfp-arrow .mfp-a {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before,
  .mfp-arrow .mfp-b {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after,
  .mfp-arrow-left .mfp-a {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before,
  .mfp-arrow-left .mfp-b {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after,
  .mfp-arrow-right .mfp-a {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before,
  .mfp-arrow-right .mfp-b {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-ie7 .mfp-img {
  padding: 0; }

.mfp-ie7 .mfp-bottom-bar {
  width: 600px;
  left: 50%;
  margin-left: -300px;
  margin-top: 5px;
  padding-bottom: 5px; }

.mfp-ie7 .mfp-container {
  padding: 0; }

.mfp-ie7 .mfp-content {
  padding-top: 44px; }

.mfp-ie7 .mfp-close {
  top: 0;
  right: 0;
  padding-top: 0;
}


.mfp-fade.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.92;
}
.mfp-bottom-bar {
    display: none;
}
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}
.mfp-arrow-right::after,
.mfp-arrow-left::after {
    font-family: 'FontAwesome';
    border: none;
    color: #fff;
    font-size: 65px;
    -webkit-transition: .5s;
    transition: .5s;
}
.mfp-arrow-right::after {
    content: "\f105";
}
.mfp-arrow-left::after {
    content: "\f104";
}
.mfp-arrow-right::before ,
.mfp-arrow-left::before {
    border: none;
}
.mfp-arrow {
    height: 60px;
}
.mfp-arrow::before, 
.mfp-arrow::after, 
.mfp-arrow .mfp-b, 
.mfp-arrow .mfp-a {
    margin-top: 15px;
    top: -1px;
}
img.mfp-img {
    padding: 40px 0px 0;
}
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;
    transform: scale(0.95);
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    transform: scale(1);
    opacity: 1;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
    transform: scale(0.95);
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.project-info {
    list-style: none;
    padding-top: 50px;
    text-align: left;
}

.project-info li {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin: 15px 0;
    color:#212121;
}

.project-info li span {
    font-family: 'Poppins', sans-serif;
    color: #6d747c;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
}
.project-title {
    padding-top: 20px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
    color: #212121;
	margin-bottom: 20px;
}

.project-details {
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.8;
    padding-bottom: 50px;
}

.project-details p {
    color:#212121;
    font-size: 16px;
    font-weight: 300;
	margin-top: 35px;
}

.portfolio-slider {
	margin-bottom:30px;
}
.portfolio-slider .slick-dots {
	position:absolute;
	bottom:25px;
}

.portfolio-right .project-detail-box {
	margin-top:10px;
}

.portfolio-right .project-detail-box.mt-50 {
	margin-top:50px;
}

.portfolio-right .project-detail-box li strong {
    min-width: 110px;
    display: inline-block;
}

.portfolio-right .project-detail-box li strong.display-block {
	display: block;
}

.portfolio-right .project-detail-box li {
	line-height:30px;
	list-style: none;
}

.portfolio-right .project-detail-box li a {
	color:#888;
}

.portfolio-right .project-detail-box li a:hover {
	color:#3949ab;
}

.project-type-list { 
	margin-top:30px;
}

.project-type-list li {
    padding: 10px 0;
    border-bottom: 1px solid #E8E8E8;
    font-weight: 300;
    font-size: 14px;
	list-style: none;	
}
.project-type-list li i {
    margin-right: 10px;
}

.portfolio-right .project-type-list li, .portfolio-right .project-detail-box li {
	text-transform:uppercase;
}


.portfolio-nav a {
  overflow: hidden;
  color: inherit;
  cursor: default;
  display:block;
}
.portfolio-nav a.disabled {
  opacity: .6;
  pointer-events: none;
}
.portfolio-nav a.disabled > span {
  cursor: default;
}
.portfolio-nav a.disabled > span:hover {
  color: inherit;
}
.portfolio-nav a.disabled:hover {
  opacity: .4;
}
.portfolio-nav a.prev i {
  margin-right: 10px;
}
.portfolio-nav a.showall {
  text-align: center;
}
.portfolio-nav a.showall span {
  padding: 36px 0;
}
.portfolio-nav a.showall span i {
  font-size: 24px;
  display: block;
}
@media only screen and (max-width: 767px) {
  .portfolio-nav a.showall span i {
    font-size: 30px;
  }
}

.portfolio-nav a.next {
  text-align: right;
}
.portfolio-nav a.next i {
  margin-left: 10px;
}
.portfolio-nav a > span {
  display: inline-block;
  padding: 40px 0;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.portfolio-nav a > span:hover {
  color: #212121;
}
.portfolio-nav a > span i {
  font-size: 14px;
}
.portfolio-nav a > span i:before {
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  .portfolio-nav a > span {
    font-size: 0;
  }
  .portfolio-nav a > span i {
    font-size: 30px;
  }
}

/*=============================================
                        Pricing Table
=============================================*/
.pricing-table {
    margin: 40px 0;
}
.pricing-box {
    padding: 40px;
    text-align: center;
    background: #fff;
	transition: all 0.2s ease;
}

.pricing-box:hover {
    box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);
}

.pricing-box h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
	color: #bdbdbd;
}
.pricing-box h2 {
    font-family: 'Poppins', sans-serif;
	margin-top: 25px;
    margin-bottom: 25px;
	color: #757575;
}
.pricing-box h2 sup {
    font-size: 25px;
    top: -15.6px;
}
.pricing-box h2 span {
    font-weight: 700;
}

.pricing-box ul{margin:20px 0;padding:0;}
.pricing-box li{display:block;margin-bottom:10px;list-style:none;}

.pricing-box-bottom{margin-top:40px;}

.pricing-table-featured {
    margin: 5px 0;
}

.pricing-table-featured .pricicng-feature {
    min-height: 180px;
}

section.dark-block .pricing-box {
	background-color:#212121;
}

section.dark-block .pricing-box h4 {
	color:#fff;
}


.pricing-box h2.red-color {
	color: #cb2d3e;
}
/*=============================================
                        Our Team
=============================================*/
.member {
    margin: 40px 0;
    padding: 0 10px;
}
.team-slider .member {
    margin: 20px 0 30px 0;
    padding: 0 10px;
}
.team-fullwidth .member {
    margin: 0;
    padding: 0;
}
.p0.member {
    margin: 0;
    padding: 0;
}
.team-member-container {
    position: relative;
    overflow: hidden;
}
.team-member-img img {
    width: 100%;
}
.team-member-container .member-caption {
    width: 100%;
    position: absolute;
    z-index: 2;
    height: 100%;
    opacity: 0;
	visibility:hidden;
    top: 0;
    background-color: rgba(57, 73, 171, 0.9);
}
.team-member-container:hover .member-caption {
    opacity: 1;
	visibility:visible;
}
.member-description {
    width: 100%;
    height: 100%;
}
.member-description-wrap {
	position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    margin-top: 0px;
    padding: 25px;
    height: 100%;
}
.member-description .member-title, .member-description .member-subtitle {
    position: relative;
    left: 0;
    color: #fff;
    width: 100%;
    opacity: 0;
    visibility: hidden;
}

.member-description .member-title{
	top: 0;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: -35px;
}

.member-description .member-subtitle {
    top: 0;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.team-member-container:hover .member-description .member-title {
    opacity: 1;
	visibility: visible;
	top: 30px;
}

.team-member-container:hover .member-description .member-subtitle {
    opacity: 1;
	visibility: visible;
	top: 25px;
}

.member-icons, .product-btn-group {
	position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    padding: 25px;
    margin-bottom: 0;
}

.member-icons li, .product-btn-group li {
    display: inline-block;
    position: relative;
    bottom: -40px;
    opacity: 0;
    visibility: hidden;
    transform: translateZ(0);
    margin-right: 5px;
}
.member-icons li a, .product-btn-group li a {
    display: block;
    color: #fff;
}
.member-icons li a:hover, .product-btn-group li a:hover {
    color: #fff;
}

.team-member-container:hover .member-icons li, .product-wrap:hover .product-btn-group li {
    opacity: 1;
	visibility: visible;
	bottom: 0;
}

.team-member-container:hover .member-icons li:nth-child(1), .product-wrap:hover .product-btn-group li:nth-child(1) {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.team-member-container:hover .member-icons li:nth-child(2), .product-wrap:hover .product-btn-group li:nth-child(2) {
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.team-member-container:hover .member-icons li:nth-child(3), .product-wrap:hover .product-btn-group li:nth-child(3) {
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
}

.corporate-member .member-description-wrap, .startup-member .member-description-wrap, .media-member .member-description-wrap, .product-description-wrap {
	position: absolute;
    left: 0;
    width: 100%;
    margin-top: -40px;
}

.startup-member .member-description-wrap .member-icons, .corporate-member .member-description-wrap .member-icons, .media-member .member-description-wrap .member-icons, .product-description-wrap .product-btn-group{
	top: 50%;
}

.corporate-member h4, .startup-member h4{
	margin-top:30px;
	margin-bottom: 5px;
}

.startup-member .member {
	padding:0;
}

.media-member h4 {
	margin-top:0;
}

.media-member .member-icons li a, .product-btn-group li a {
    display: block;
    color: #fff;
    background: #3949ab;
    width: 35px;
    height: 35px;
    line-height: 37px;
    border-radius: 100px;
}

.media-member .member-icons li a:hover, .product-btn-group li a:hover {
    color: #fff;
	background: #212121;
}


/*=============================================
                        Our Blog
=============================================*/
.blog-slider .post {
    margin: 20px 0;
    padding: 0 10px;
}

.post-info {
    padding: 40px;
    background-color: #fff;
    transition: all 0.2s ease;
    border: 1px solid #eeeeee;
}

.post .post-img, .service-box .service-img {
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.post .post-img img, .service-box .service-img img {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  z-index: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.post:hover .post-img img, .service-box:hover .service-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.post-info h3 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom:0px;
}
.post-info h6 {
   color: #3949ab;
   font-size:12px;
   margin-top:5px;
}

.post-info a {
    color:#212121;
}

.post-info a:hover {
    color:#3949ab;
}

.post-info p {
	margin-top: 20px;
	color: #bdbdbd;
	font-size:12px;
}

.post-info .extras-wrap{
	padding: 0px 14px 0px 0px;
	display:inline-block;
	font-size: 16px;
    color: #9e9e9e;
}

.post-info .extras-wrap i {
    position: relative;
    top: 1px;
    padding: 0px 5px 0px 0px;
}

a.readmore {
    letter-spacing: 2px;
    color: #212121;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700; 
}

a:hover.readmore {
    color: #3949ab;
}

a.readmore > span {
    display: inline-block;
    vertical-align: middle;
}

.post-info hr {
    border-top: 1px solid #e0e0e0;
    width: 100%;
    height: 1px;
}

#blog-grid > .container > div:nth-last-child(-n+3) {
  margin-bottom: 0;
}

.blog-grid-video .video-alpha .video-play {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    margin-top: -25px;
    border: 2px solid #fff;
    border-radius: 100%;
    color: #fff;
    line-height: 50px;
    text-align: center;
    z-index: 300;
}

.blog-grid-video .video-alpha .video-play i {
    font-size: 30px;
}
.blog-grid-video .video-alpha .video-play:hover {
    color: #212121;
    background-color: #fff;
}
.blog-grid-video .video-alpha .video-play i.fa-play {
    margin-left: 5px;
}

.blog-masonry-item {
    margin-bottom: 25px;
}

.widget{
  margin-bottom: 30px;
}

.widget-title{
  position: relative;
  text-transform: uppercase;
  margin-bottom: 45px;
}

.widget-title:before{
    content: '';
    position: absolute;
    top: 100%;
    margin-top: 10px;
    left: 0;
    width: 100%;
    border-top: 1px solid #e0e0e0;
}

.widget-about-title{
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 0;
}
 
/* 10.5 Widget Search */



.search-form{
  position: relative;
}


.search-field{
  border-color: #e9e9e9!important;
  background:#fff;
}

.search-field:focus {
	border-color: #3949ab!important;
}

.fullscreen-search-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Widget Categories */



.widget_categories ul li:first-child, .widget_archive ul li:first-child{
  margin-top: 0;
}

.widget_categories ul li, .widget_archive ul li{
  margin-top: 20px;
  line-height: 25px;
  list-style: none;
}

.widget_categories ul li a, .widget_archive ul li a{
  color: #888;
  font-weight:100;
}

.widget_categories ul li a:hover, .widget_archive ul li a:hover{
  color:#3949ab;
  text-decoration: none;
}

/* Widget Tags */

.post-tags a {
    display: inline-block;
    padding: 5px 11px;
    margin: 0 5px 15px 0;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    color: #888;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.post-tags a:hover{
  background: #3949ab;
  border: 1px solid #3949ab;
  color:#fff;
  text-decoration: none;
}


.pagination {
    display: block;
    margin: 80px auto 0 auto;
    text-align: center;
}

.pagination>li {
    display: inline-block;
	margin-right:5px;
}

.pagination>li>a, .pagination>li>span {
    float: left;
    padding: 8px 15px;
    color: #3949ab;
    background-color: #fff;
    border: 1px solid #eee;
	border-radius: 100%;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-radius: 100%;
    padding: 8px 16px;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-radius: 100%;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    color: #fff;
    background-color: #3949ab;
    border-color: #3949ab;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
	color: #fff;
    background-color: #3949ab;
    border-color: #3949ab;
}

.blog-standard {
	margin-top:20px;
}

.blog-standard blockquote{
  margin:40px 0;
  background-color: #fff;
  padding: 30px;
  position: relative;
  border-left-color:#3949ab;
  font-size: inherit;
  line-height: 1.7;
  color: #888;
}

.post-controls{
  margin-top: 30px;
  overflow: hidden;
}

.post-share li{
  display: inline-block;
  padding-right: 10px;
  margin:0;
}

.post-controls .post-share{
  float: left;
}

.post-controls .comments-info{
  float: right;
}

.post-controls .post-share li a {
	color:#212121;
}

.post-controls .post-share li a:hover {
	color:#3949ab;
}



/* 10.3 Comments */



.comments-info a{
  color: #212121;
}

.comments-info a:hover{
  color: #3949ab;
  text-decoration: none;
}

.comment-respond{
  margin-top: 70px;
}

.comment-reply-title{
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: uppercase;
}


.comment-box {
    margin-bottom: 80px;
}
.post-comment {
    margin: 30px 0;
}
li.post-comment {
    list-style: none;
}
.comment-content {
    padding: 20px;
    background-color: #fff;
	border: 1px solid #f1f1f1;
}
.post-comment .avatar {
    margin-right: 22px;
    float: left;
}
.post-comment .avatar img {
    width: 74px;
    height: 74px;
    border-radius: 100px;
}
.post-comment .post-body {
    overflow: hidden;
}
.post-comment .comment-header {
    line-height: 1;
    font-size: 13px;
    padding-right: 46px;
    margin-top: 3px;
    margin-bottom: 10px;
}
.post-comment .author a {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #212121;
}
.post-comment .comment-header .time-ago a {
    font-size: 12px;
    color: #888;
    margin-left: 6px;
}
.post-comment .reply a {
    font-size: 10px;
    color: #888;
    margin-right: 6px;
    float: right;
	background-color: #ececec;
    padding: 5px 10px;
}
.post-comment .reply-comment {
    padding-left: 90px;
}
.post-comment .reply-comment .avatar img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
}

.comment-form .form-control {
    height: 55px;
    border: 1px solid #eee;
}

.comment-form textarea.form-control {
	height: auto;
}


/*=============================================
                        Footer
=============================================*/

.footer-height {
  height: 804px;
}

.footer-three-height {
  height:287px;
}

@media only screen and (min-width: 992px) {
  #footer-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .footer-height {
    height: 505px;
  }
}

.footer{
  font-size:16px;
}

.footer-main{
  padding:60px 0 60px;
  background-color: #f5f5f5;
  position: relative;
}


.footer .widget-title{
    position: relative;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: #212121;
    font-family: 'Poppins', sans-serif;
}

.footer .widget-text p {color:#9e9e9e;}

.footer .widget-title:before{
  content:'';
  position: absolute;
  top:100%;
  margin-top: 10px;
  left: 0;
  width: 40px;
  border-top: 1px solid #212121;
}

.footer .widget-links{
  font-family: 'Source Sans Pro', sans-serif;
}

.footer .widget-links li{
  margin-bottom: 10px;
  list-style:none;  
  color: #9e9e9e;
}

.footer .widget-links li i {
	color:#9e9e9e;    
	padding-right: 10px;
}

.footer .widget-links a{
  color: #9e9e9e;
}

.footer .widget-links a:hover{
  color:#3949ab;
  text-decoration: none;
}

.footer-copyright{
   padding: 50px 0;
   background-color: #eeeeee;
}

.copy-right{margin-top:5px;color:#616161;}

.footer-copyright ul.social-media{
  float:left;
  display:block;
  margin-bottom:0;
  margin-top: 5px;
}

.footer-copyright ul.social-media li {
	display:inline-block;
	margin-right: 30px;
}

.footer-copyright ul.social-media li:last-child {
	margin-right:0;
}

.footer-copyright ul.social-media li a{
  font-size: 20px;
  display: inline-block;
  color: #616161;
}

.footer-copyright ul.social-media li a:hover{
  color: #3949ab;
}

.popular-tags a{
    display: inline-block;
    padding: 5px 11px;
    margin: 0 5px 15px 0;
    border: 1px solid #9e9e9e;
    color: #9e9e9e;
	background:transparent;
}

.popular-tags a:hover{
  background: #3949ab;
  border: 1px solid #3949ab;
  color:#fff;
  text-decoration: none;
}


.flat-footer ul.social-media{
  float:none;
  display:block;
  margin-bottom:0;
  margin-top: 5px;
}

.flat-footer ul.social-media li {
	display:inline-block;
	margin-right: 17px;
}

.flat-footer ul.social-media li:last-child {
	margin-right:0;
}

.flat-footer ul.social-media li a{
  font-size: 20px;
  display: inline-block;
  color: #fff;
}

.flat-footer ul.social-media li a:hover{
  color: #3949ab;
}

ul.footer-gallery {margin:0;}

ul.footer-gallery li {
    display: inline-block;
    width: 24%;
    padding: 0 2px 0px 0;
    margin-bottom: 5px;
}
ul.footer-gallery li img {
    max-width: 100%;
    width: 100%;
}

.footer-gallery-box {
    position: relative;
    overflow: hidden;
}

.footer-gallery-box .skin-overlay {
    background-color: rgba(30, 192, 255, 0.9);
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
}
.footer-gallery-box:hover .skin-overlay{
    opacity: 1;
}
.footer-gallery-box .zoom-wrap {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
}

.footer-gallery-zoom {
    position: absolute;
    left: 0;
    top: 40%;
    width: 100%;
}

.footer-gallery-zoom li {
    position: relative;
    bottom: -40px;
    opacity: 0;
    visibility: hidden;
    transform: translateZ(0);
}

.footer-gallery-box:hover .footer-gallery-zoom li{
	bottom: 0;
    opacity: 1;
    visibility: visible;
	-webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.footer-gallery-zoom li a {
	color:#fff;
}

.footer-gallery-zoom li a:hover, .footer-gallery-zoom li a:focus {
	color:#212121;
}

.logo-footer {
	height: 34px;
    margin-bottom: 30px;
}

.contact-widget.widget ul li {
  padding-left: 25px;
  position: relative;
}
.contact-widget.widget ul li i {
  position: absolute;
  left: 0;
  top: 1px;
}

/*=============================================
                Contact Us Page
=============================================*/

.form-group {
    margin-bottom: 30px;
}
.form-control {
    height: 60px;
    padding: 20px 30px;
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    color: #212121;
    border: 2px solid #eee;
    border-radius: 0;
}
.form-control:focus {
    border-color: #3949ab;
}

.contact-us p, .contact-us address {
	font-size:14px;
}

.contact-us h3 {
	margin-top:0;
}

.contact-us address {
	margin-bottom:40px;
	line-height:25px;
}

.contact-us address a {
	color:#3949ab;
}

.contact-us address a:hover {
	color:#212121;
}

.search-submit{
  position: absolute;
  right: 20px;
  top:20px;
  background: none;
  border:0;
  padding: 0;
  font-size:20px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color:#e0e0e0;
}

.search-submit:hover{
  color: #3949ab;
}

.form-style-one .form-control {
	border: 2px solid #eee;
}

.form-style-one .form-control:focus {
    border-color: #212121;
}

.form-style-one .has-error .form-control {
    border-color: #616161;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-style-one .has-error .help-block, .form-style-one .has-error .control-label, .form-style-one .has-error .radio, .form-style-one .has-error .checkbox, .form-style-one .has-error .radio-inline, .form-style-one .has-error .checkbox-inline, .form-style-one .has-error.radio label, .form-style-one .has-error.checkbox label, .form-style-one .has-error.radio-inline label, .form-style-one .has-error.checkbox-inline label {
    color: #616161;
}

/*=============================================
                Google Maps
=============================================*/

.map-section {
	position:relative;
	min-height: 500px;
}

.map-parallax {
	position: fixed;
    width: 100%;
    height: 45%;
    z-index: -3;
}
#myMap, #map-style-2, #map-style-3 {
    min-height: 500px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
}

#myMap.wide, #map-style-2.wide, #map-style-3.wide {
    height: 500px;
    width: 100%;
    position: relative!important;
}

#mapcontent p{
    margin: 0;
    font-size: 16px;
}



/*=============================================
                Go Top
=============================================*/

#back-to-top {
    position: fixed;
    bottom: 75px;
    right: 40px;
    z-index: 999;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background: #3949ab;
    color: #fff;
    cursor: pointer;
    border: 0;
    border-radius: 50%;
    text-decoration: none;
    opacity: 0;
	box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.05);
	font-size:14px;
}
#back-to-top:hover {
    background: #212121;
	color:#fff;
}
#back-to-top.show {
    opacity: 1;
}

/*=======================================================
     SLIDERS
========================================================*/
.hero-fullslide {
    position: relative;
    height: 100vh;
    padding: 120px 0;
    overflow: hidden;
}

.hero-fullslide .container, .home-fullslide .container {
    z-index: 2;
}
.hero-fullslide .container, .home-fullslide .container {
    position: relative;
    padding-top: 60px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.default-slider, .home-fullslide {
    position: relative;
    height: 768px;
    overflow: hidden;
}

.home-fullslide.parallax > .parallax-img {
	height:100%;
	top:0;
}

.half-height-slider{
    position: relative;
    top: 0;
    background-color: #212121;
    z-index: 1;
}

.default-slider .container {
	padding-top:60px;
}

.default-slider.slick-dotted.slick-slider, .blog-grid-slider.slick-dotted.slick-slider{
	margin-bottom:0;
}



.home-slider {
	height:100vh;
	padding:0;
}

.home-slider .default-slider, .home-slider .slick-list, .home-slider .slide-img, .home-slider .hero-text-wrap {
	height:100vh;
	width:100%;
}


.default-slider .slide {
    position: relative;
    height: 768px;
}

.default-slider .slick-dots {
	bottom:40px;
}

.default-slider .slick-next, .blog-grid-slider .slick-next
{
    right:0px;
}

.default-slider .slick-prev, .blog-grid-slider .slick-prev
{
    left: 0px;
}

.default-slider .slick-dots li button {
    font-size: 0;
    width: 40px;
    height: 2px;
    padding: 0px;
    cursor: pointer;
    border: 0;
    outline: none;
    background: rgba(195, 195, 195, 0.5);
}

section.dark-block .default-slider .slick-dots li button {
	background: rgba(33, 33, 33, 0.5);
}

.default-slider .slick-slide.slick-active .zoom-text, .hero-text-slider .slick-active.zoom-text{
		-webkit-animation-delay: .4s;
		animation-delay: .4s;
		-webkit-animation-duration: .6s;
		animation-duration: .6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: zoomIn;
		animation-name: zoomIn;
}

.default-slider .slick-active .fade-left-text h1, .default-slider .slick-active .fade-left-text h2, .default-slider .slick-active .fade-left-text h3, .default-slider .slick-active .fade-left-text h4, .default-slider .slick-active .fade-left-text h5, .default-slider .slick-active .fade-left-text h6, .default-slider .slick-active .fade-left-text p{
		-webkit-animation-delay: .4s;
		animation-delay: .4s;
		-webkit-animation-duration: .6s;
		animation-duration: .6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: fadeInLeft;
		animation-name: fadeInLeft;
}

.default-slider .slick-active .fade-center-text h1, .default-slider .slick-active .fade-center-text h2, .default-slider .slick-active .fade-center-text h3, .default-slider .slick-active .fade-center-text h4, .default-slider .slick-active .fade-center-text h5, .default-slider .slick-active .fade-center-text h6, .default-slider .slick-active .fade-center-text p {
		-webkit-animation-delay: .4s;
		animation-delay: .4s;
		-webkit-animation-duration: .6s;
		animation-duration: .6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: fadeInUp;
		animation-name: fadeInUp;
}

.default-slider .slick-active .fade-left-text h5, .default-slider .slick-active .fade-center-text h5 {
	-webkit-animation-delay: .5s;
		animation-delay: .5s;
}

.default-slider .slick-active .fade-left-text p, .default-slider .slick-active .fade-center-text p {
	-webkit-animation-delay: .6s;
		animation-delay: .6s;
}

.hero-text{
	display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 100%;
    z-index: 10;
    color: #fff;
}

.overlay-bg {
    background: rgba(0,0,0,0.6);
    width: 100%;
    height: 100%;
    position: absolute;
	z-index: -1;
	top:0;
	left:0;
}

.hero-text-wrap.purple-overlay-bg {
	background: rgba(33,150,243,0.82);
	width: 100%;
    height: 101vh;
    position: absolute;
	z-index: 1;
}

.overlay-bg-dark {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
	z-index: -1;
	top:0;
	left:0;
}

.overlay-bg-light {
    background: rgba(255,255,255,0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}


.bg-video .hero-text h1, 
.bg-video .hero-text h2, 
.bg-video .hero-text h3,
.bg-video .hero-text h4,
.bg-video .hero-text h5,
.bg-video .hero-text h6, 
.bg-video .hero-text p{
		-webkit-animation-delay: .4s;
		animation-delay: .4s;
		-webkit-animation-duration: .6s;
		animation-duration: .6s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation-name: fadeInUp;
		animation-name: fadeInUp;
}

.bg-video .hero-text h5 {
		-webkit-animation-delay: .5s;
		animation-delay: .5s;
}

.bg-video .hero-text p {
		-webkit-animation-delay: .4s;
		animation-delay: .4s;
}

.slider-bg {
	border: 0;
    margin: 0;
    height: 100vh;
    background-color: #222225;
}
.slider-bg .slides {
    height: 100%;
}

.slider-bg .slides > li {
    height: 100%;
}

.slider-bg .hero-text{
	display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 100%;
    z-index: 10;
    color: #fff;
}
.slider-bg .hero-text P{
	line-height: 50px;
    font-size: 35px;
}

.slider-bg .hero-text h1 {
    font-size: 120px;
    line-height: 120px;
	letter-spacing: -5px;
}

.slide-img, .parallax-img {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.full-screen-bg {
	height: 100vh;
    background-color: #212121;
 	min-height: 100vh;
}

.hero-text-wrap {
	display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 200;
	background-color: rgba(0, 0, 0, 0.65);
}

.video-container video.fillWidth {
    width: 100%!important;
	margin-left: 0 !important;
}

.video-container .filter {display:none;}

.video-container video {
    position: absolute;
    top: 0%;
    left:0%;
    object-fit: cover;
    height: 100%!important;
    width: 100%!important;
}

.embed-container { position: relative; padding-bottom: 56.25%; overflow: hidden; max-width: 100%; height: auto; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.about-video {
	padding-bottom: 88.25%;
}
.about-video iframe {
	height:318px;
}

.arcon-video-box {
  position: relative;
  overflow: hidden;
}
.arcon-video-box:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.arcon-video-box:hover .arcon-video-box_overlay {
  background-color: rgba(0, 0, 0, 0.25);
}
.arcon-video-box:hover .arcon-video-box_overlay .play-button {
  background-color: #fff;
  color: #2d2d2d;
}
.arcon-video-box img {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.arcon-video-box_overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}
.play-button {
  cursor: pointer;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #fff;
  border-radius: 50%;
  line-height: 80px;
  color: #fff;
  text-align: center;
  font-size: 50px;
  position: relative;
  z-index: 10;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.play-button i {
  line-height: inherit;
}
.play-button:hover {
  background-color: #fff;
  color: #2d2d2d;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.btn[data-play-button] {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.btn[data-play-button]:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.inline-btn p {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1em;
    margin: 0 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    vertical-align: middle;
}

.inline-btn i{
	font-size:20px;
}

.video-alpha .video-play {
    position: absolute;
    top: 65%;
    left: 50%;
    margin-top: 0px;
    margin-left: -37px;
    width: 74px;
    height: 74px;
    font-size: 30px;
    line-height: 71px;
    text-align: center;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 100%;
}
.video-alpha .video-play i.fa-play {
    margin-left: 5px;
}

.music-event .video-play{
    font-size:100px;
	color:#fff;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1); 
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1); 
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);  
	display:inline-block;
}

.music-event .video-play:hover{
	-webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.5);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.5);   
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.5);
    transition: all 200ms ease-in;
    transform: scale(1.5);
}




.hero-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.hero-video video {
  position: absolute;
  max-width: none;
}

.about-me {
	margin-bottom:20px;
}

.about-me img {
	width:200px;
}

.bg-flex.bg-flex-left {
    left: 0;
}

.bg-flex.bg-flex-right {
    right: 0;
}

.bg-flex{
  padding: 0;
  position: absolute!important;
  top: 0px;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}

.bg-flex-holder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.bg-flex-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.bg-flex-right{
  position: absolute;
  padding: 0;
  right:0;
  top:0;
  bottom: 0;
  overflow: hidden;
}


.col-about-right {
    padding-left: 60px;
}

.col-about-left {
    padding-right: 60px;
}

.row-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.row-flex:before {
  content: none;
}
.row-flex > [class*="col-"] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
@media only screen and (max-width: 767px) {
  .row-flex > [class*="col-"]:not([class*="col-xs"]) {
    width: 100%;
  }
}
.row-flex > [class*="col-"] .col-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
}

.col-inner.spacer {
    padding: 100px 50px;
}

.row-flex.flex-center > [class*="col-"] {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .row-flex.full-width-flex > [class*="col-"] {
    padding-left: 6.5%;
    padding-right: 6.5%;
  }
}
.row-flex.padding-row > [class*="col-"] {
  padding: 7.5% 6.5%;
}
.row-flex.flex-horizontal > [class*="col-"]:last-child {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.row-flex.flex-spacing .column-inner {
  margin-left: 0;
  margin-right: 0;
}




.social-networks {
  padding: 0;
  list-style: none;
}
.social-networks > li {
  display: inline-block;
  margin-right: 12px;
}
.social-networks > li > a {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 47px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #e8e8e8;
    color: #212121;
    border-radius: 100%;
}
.social-networks > li > a:hover {
  color: #fff;
}
.social-networks > li.social-icon-fb > a:hover {
  background-color: #3b5998;
  border-color: #3b5998;
}
.social-networks > li.social-icon-tw > a:hover {
  background-color: #55acee;
  border-color: #55acee;
}
.social-networks > li.social-icon-lk > a:hover {
  background-color: #0077b5;
  border-color: #0077b5;
}
.social-networks > li.social-icon-be > a:hover {
  background-color: #053eff;
  border-color: #053eff;
}
.social-networks > li.social-icon-de > a:hover {
  background-color: #ea4c89;
  border-color: #ea4c89;
}

.social-networks > li.social-icon-sk > a:hover {
  background-color: #00aff0;
  border-color: #00aff0;
}

.social-networks > li > a i {
    font-size: 20px;
}

/*=======================================================
      PROGRESS BAR
========================================================*/

.progress-bar-title{
  margin-bottom: 10px;
}

.progress-bar-title,
.progress-bar{
  font-size: 16px;
  color: #212121;
}

.progress{
  height: 5px;
  margin-bottom: 40px;
  box-shadow: none;
  background:#ececec;
  overflow: visible;
}

.progress-bar{
  position: relative;
  border-radius: 4px;
  background-color: #212121;
  box-shadow: none;
  color: inherit;
  width: 20%;
}

.progress-bar span{
  position: absolute;
  top: -30px;
  right: 0px;
  color: #212121;
}


section.color-block .progress-bar-title, .progress-bar, section.color-block .progress-bar span {
	color:#fff;
}

section.color-block .progress-bar {
	background-color:#fff;
}

section.color-block .progress {
	background-color:rgba(255, 255, 255, 0.2);
}

section.default-block .progress-bar-title, .progress-bar, section.default-block .progress-bar span {
	color:#212121;
}

section.default-block .progress-bar {
	background-color:#3949ab;
}

section.default-block .progress {
	background-color:#eee;
}
	

/*=======================================================
      TESTIMONIALS
========================================================*/


.testimonial-item h4, .testimonial-item h5 {
    color: #212121;
	font-family: 'Poppins', sans-serif;
}
.testimonial-item h4 {
	font-weight: 300;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 25px;
}


.testimonial-item h5{
	margin-bottom:3px;    
	font-size: 25px;
    font-weight: 700;
}
.testimonial-item p {
    color: #9e9e9e;
    font-size: 16px;
    text-transform: uppercase;
}

.testimonial .testimonial-item img {
	width:120px;
	height:120px;
	text-align: center;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
}

.testimonial-item {
	padding-left: 25px;
    padding-right: 25px;
}

.testimonial-item:focus {
	outline: none;
}

.testimonial-item .testimonial-content {
    background-color: #fff;
    box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);
    padding: 40px;
    margin: 50px auto;
	text-align: center;
	max-width: 750px;
	border-radius: 0px 50px 0px 50px;
	-moz-border-radius: 0px 50px 0px 50px;
	-o-border-radius: 0px 50px 0px 50px;
	-webkit-border-radius: 0px 50px 0px 50px;
}

section.parallax-bg .testimonial-item .testimonial-content {
	background-color:transparent;
	box-shadow:none;
	margin-top: 0;
    margin-bottom: 0;
    padding: 0 50px;
}

section.parallax-bg .testimonial-item h4, section.parallax-bg .testimonial-item h5, section.parallax-bg .testimonial-item p, section.parallax-bg .testimonial .slick-prev:before, section.parallax-bg .testimonial .slick-next:before {
	color:#fff;
}

section.parallax-bg .testimonial .slick-prev:before, section.parallax-bg .testimonial .slick-next:before {
	opacity:.75;
}

section.parallax-bg .testimonial .slick-prev:hover:before, section.parallax-bg .testimonial .slick-next:hover:before {
	opacity:1;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
	z-index:10;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: #fff;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.testimonial .slick-prev:before,
.testimonial .slick-next:before
{
    font-family:"Ionicons";
    font-size: 35px;
    line-height: 1;
    opacity: 0.5;
    color: #212121;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.testimonial .slick-prev:hover:before, .testimonial .slick-prev:focus:before, .testimonial .slick-next:hover:before, .testimonial .slick-next:focus:before{
	opacity: 1;
}


.slick-prev
{
    left: 25px;
}

.testimonial .slick-prev:before
{
    content: '\f2ca';
}

.slick-next
{
    right: 25px;
}

.testimonial .slick-next:before
{
    content: '\f30f';
}


/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

.testimonial .slick-dots li button, .team-slider .slick-dots li button, .blog-slider .slick-dots li button, .portfolio-slider .slick-dots li button, .model-slider .slick-dots li button {
    font-size: 0;
    width: 12px;
    height: 12px;
    padding: 0px;
    cursor: pointer;
    border: 0;
    outline: none;
    background: rgba(195, 195, 195, 0.5);
    border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
}

.model-slider .slick-dots li button {
	width:5px;
	height:5px;
}

.testimonial .slick-dots li.slick-active button, .team-slider .slick-dots li.slick-active button {
    background-color: #3949ab;
}

section.parallax-bg .testimonial .slick-dots li.slick-active button, .model-slider .slick-dots li.slick-active button {
	background-color:#fff;
}

.model-slider .slick-dots li.slick-active button{
	width:15px;
	height:15px;
}

.model-slider .slick-dots {
	bottom:-50px;
}

.testimonial .slick-dots li button:hover,
.testimonial .slick-dots li button:focus,
.model-slider .slick-dots li button:hover,
.model-slider .slick-dots li button:focus
{
    outline: none;
}

.default-slider .slick-prev:before, .blog-grid-slider .slick-prev:before {
    content: '\f3d5';
}

.default-slider .slick-next:before, .blog-grid-slider .slick-next:before {
    content: '\f3d6';
}

.default-slider .slick-prev:before, .default-slider .slick-next:before, .blog-grid-slider .slick-prev:before, .blog-grid-slider .slick-next:before, .product-slider .slick-prev:before, .product-slider .slick-next:before { 
	font-family:"Ionicons";
    font-size: 35px;
    line-height: 1;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.default-slider .slick-prev,
.default-slider .slick-next,
.blog-grid-slider .slick-prev, 
.blog-grid-slider .slick-next {
	border: 2px solid rgba(255,255,255,0.7);
    width: 50px;
    height: 50px;
	color:#fff;
}

.default-slider .slick-prev:hover, .default-slider .slick-next:hover, .blog-grid-slider .slick-prev:hover, .blog-grid-slider .slick-next:hover{
    color: #212121;
    background: #fff;
}

.default-slider .slick-prev:focus, .default-slider .slick-next:focus  {
	outline: none;
}

.default-slider .slick-dots li.slick-active button {
	background-color: #fff;
}

section.dark-block .default-slider .slick-dots li.slick-active button {
	background-color:#212121;
}

section.dark-block .default-slider .slick-prev, section.dark-block .default-slider .slick-next {
	border: 1px solid rgba(33,33,33,0.7);
	color:#212121;
}

section.dark-block .default-slider .slick-prev:hover, section.dark-block .default-slider .slick-next:hover {
	color:#fff;
	background: #212121;
}


/*=======================================================
      Client Logo
========================================================*/
.client-logo {
    margin: 0px 15px;
}

.client-logo img {
    margin: auto;
	opacity: 0.7;
    filter: alpha(opacity=70);
}

.client-logo img:hover {
	opacity: 1.0;
    filter: alpha(opacity=100);
}

section.big-block .client-logo {
	padding:10%;
}

.default-slider .slide:focus, .testimonial-item:focus, .quote-item:focus, .client-logo:focus, .zoom-text:focus, .portfolio-slider .item:focus, .blog-grid-slider .item:focus, .member:focus, .blog-slider .post:focus, .product-slide .small-thumb:focus, .product-slider .big-thumb:focus, .model-slider .item-model:focus {
	outline: none;
}

/*=======================================================
      Title Hero Backgrounds
========================================================*/
.title-hero-bg {
    background-size: cover;
    -webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
	background-repeat:no-repeat;
	background-position:center center;
    position: relative;
    min-height: 550px;
}
.title-hero-bg::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.error-section .hero-text h1{
	font-size:165px;
	color:#3949ab;
	font-weight: 700;
	line-height: 1;
}

.error-section .hero-text h3 {
    font-size: 40px;
}

.error-section .hero-text h3, .error-section .hero-text p{
	color:#fff;
}

.error-section .hero-text p {
    font-size: 18px;
    font-weight: 300;
}


.page-title {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0;
    z-index: 15;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.page-title h1 {
    font-size: 50px;
    line-height: 1.5em;
    font-weight: 700;
    margin: 0;
}

/*=======================================================
      Login / Coming Soon / 404 Page
========================================================*/

.title-hero-bg.coming-soon-section {
	height:100%;
	min-height: 100vh;
}

.title-hero-bg.coming-soon-section h1, .title-hero-bg.coming-soon-section p {
	color:#fff;
}



.signup-box {
	max-width: 400px;
	width:100%;
	margin:0 auto;
	padding: 30px;
	background:#fff;
}

.v-align{
	vertical-align: middle;
	display: table-cell;	
}

.v-align a {color:#3949ab;}
.v-align a:hover {color:#212121;}

.table-display {
	display:table;
	width:100%;
	height:100%;
	table-layout:fixed;
	position:relative;
}

#signup-tabs {
  border-bottom: 0;
  margin: 0;
}
#signup-tabs > li a {
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #888;
  border-radius: 0;
  border: 1px solid #fff;
}
#signup-tabs > li a:hover {
  background-color: #3949ab;
  border: 1px solid #3949ab;
  color:#fff;
}
#signup-tabs > li.active > a {
  background-color: #3949ab;
  border: 1px solid #3949ab;
  color:#fff;
}
#signup-content {
  padding: 40px 0;
}


.countdown-container, .countdown-container-white {
  margin-top: 50px;
  margin-bottom: 50px;
}

.countdown-container .countdown li {
    border-right: 1px solid #fff;
    min-width: 100px;
    padding: 20px 0px;
    width: 200px;
}

.countdown-container .countdown li:last-child {
	 border-right: 0;
}

.countdown {
    margin-bottom: 0;
    border: 1px solid #fff;
}

.countdown li {
  display: inline-block;
  text-align: center;
}

.countdown li:last-child {
	margin-right:0;
}

.countdown li span {
	font-size: 60px;
    font-weight: 700;
    color: #fff;
}

.countdown li p {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.countdown-container-white .countdown li {
    min-width: 100px;
    padding: 5px 20px;
    width: 145px;
    background: #fff;
}

.countdown-container-white .countdown li span {
	font-size: 60px;
    font-weight: 700;
    color: #212121;
}


/*=======================================================
      Personal Resume
========================================================*/
canvas {
  width: 100%;
  height: 100%;
  position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.animate-caption {
    text-align: center;
    text-transform: uppercase;
    z-index: 15;
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
}

.animate-caption h2 {
	font-size:50px;
}

.contact-me {
	background: #fff;
    padding: 50px 40px 35px 40px;
    box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);
}

.resume-bar .progress {
	margin-bottom: 40px;
}

#resume h3 {
	color:#3949ab;
}

ul.resume-slider {
    margin: 0;
    padding: 0;
    margin-top: 30px;
}

ul.resume-slider li {
    background: #fff;
    padding: 30px;
    list-style: none;
    box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);
	margin-right: 20px;
	margin-bottom:20px;
}

ul.resume-slider li h3 {
    font-size: 14px;
    margin-bottom: 15px;
    color: #212121!important;
    margin-top: 0px;
    display: inline-block;
    margin-right: 20px;
    font-family: 'Poppins', sans-serif;
	font-feature-settings: 'lnum';
    -moz-font-feature-settings: 'lnum=1';
    -ms-font-feature-settings: 'lnum';
    -webkit-font-feature-settings: 'lnum';
    -o-font-feature-settings: 'lnum';
}

ul.resume-slider li i {
	font-size:20px;
	margin-right:5px;
}

ul.resume-slider li h2 {
    font-size: 18px;
    color: #3949ab;
    margin-top: 8px;
    text-transform: uppercase;
}

ul.social-media-dark{
  display:block;
  text-align:center;
}

ul.social-media-dark li {
	display:inline-block;
	margin-right: 12px;
}

ul.social-media-dark li:last-child {
	margin-right:0;
}

ul.social-media-dark li a{
  font-size: 16px;
  display: inline-block;
  color: #212121;
}

ul.social-media-dark li a:hover{
  color: #3949ab;
}


ul.social-top li{
	margin-right:0;
}

ul.social-top li a{
	font-size: 14px;
}


.info ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.info ul li {
    margin-bottom: 30px;
}

.info ul li h3 {
    margin: 0 0 8px 0;
    font-size: 18px;
    line-height: 1;
}

.info ul li h3 i {
    margin-right: 10px;
    color: #212121;
    font-size: 24px;
    vertical-align: middle;
    margin-top: -3px;
}

.info ul li p {
    margin: 0;
    font-weight: 300;
    font-size: 18px;
}

.timeline {
  line-height: 1.4em;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.timeline h1, .timeline h2, .timeline h3, .timeline h4, .timeline h5, .timeline h6 {
  line-height: inherit;
}

.timeline-content h3.timeline-title {
	color:#fff;
	margin-top: 0;
	font-size: 18px;
}
.timeline-content h2.timeline-title{
	color:#fff;
}

/*----- TIMELINE ITEM -----*/
.timeline-item {
  padding-left: 40px;
  position: relative;
}
.timeline-item:last-child {
  padding-bottom: 0;
}

/*----- TIMELINE INFO -----*/
.timeline-info {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 3px;
  margin: 0 0 .5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}

/*----- TIMELINE MARKER -----*/
.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}
.timeline-marker:before {
  background: #3949ab;
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.timeline-marker:after {
  content: "";
  width: 3px;
  background: #CCD5DB;
  display: block;
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 6px;
}
.timeline-item:last-child .timeline-marker:after {
  content: none;
}

.timeline-item:not(.period):hover .timeline-marker:before {
  background: transparent;
  border: 3px solid #3949ab;
}

/*----- TIMELINE CONTENT -----*/
.timeline-content {
  padding-bottom: 40px;
}
.timeline-content p:last-child {
  margin-bottom: 0;
}

/*----- TIMELINE PERIOD -----*/
.period {
  padding: 0;
}
.period .timeline-info {
  display: none;
}
.period .timeline-marker:before {
  background: transparent;
  content: "";
  width: 15px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 30px;
  position: absolute;
  border-top: 3px solid #CCD5DB;
  border-bottom: 3px solid #CCD5DB;
}
.period .timeline-marker:after {
  content: "";
  height: 32px;
  top: auto;
}
.period .timeline-content {
  padding: 40px 0 70px;
}
.period .timeline-title {
  margin: 0;
}

/*----------------------------------------------
    MOD: TIMELINE SPLIT
----------------------------------------------*/
@media (min-width: 768px) {
  .timeline-split .timeline, .timeline-centered .timeline {
    display: table;
  }
  .timeline-split .timeline-item, .timeline-centered .timeline-item {
    display: table-row;
    padding: 0;
  }
  .timeline-split .timeline-info, .timeline-centered .timeline-info,
  .timeline-split .timeline-marker,
  .timeline-centered .timeline-marker,
  .timeline-split .timeline-content,
  .timeline-centered .timeline-content,
  .timeline-split .period .timeline-info,
  .timeline-centered .period .timeline-info {
    display: table-cell;
    vertical-align: top;
  }
  .timeline-split .timeline-marker, .timeline-centered .timeline-marker {
    position: relative;
  }
  .timeline-split .timeline-content, .timeline-centered .timeline-content {
    padding-left: 30px;
  }
  .timeline-split .timeline-info, .timeline-centered .timeline-info {
    padding-right: 30px;
  }
  .timeline-split .period .timeline-title, .timeline-centered .period .timeline-title {
    position: relative;
    left: -45px;
  }
}

/*----------------------------------------------
    MOD: TIMELINE CENTERED
----------------------------------------------*/
@media (min-width: 992px) {
  .timeline-centered,
  .timeline-centered .timeline-item,
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-marker,
  .timeline-centered .timeline-content {
    display: block;
    margin: 0;
    padding: 0;
  }
  .timeline-centered .timeline-item {
    padding-bottom: 40px;
    overflow: hidden;
  }
  .timeline-centered .timeline-marker {
    position: absolute;
    left: 50%;
    margin-left: -7.5px;
  }
  .timeline-centered .timeline-info,
  .timeline-centered .timeline-content {
    width: 50%;
  }
  .timeline-centered > .timeline-item:nth-child(odd) .timeline-info {
    float: left;
    text-align: right;
    padding-right: 30px;
  }
  .timeline-centered > .timeline-item:nth-child(odd) .timeline-content {
    float: right;
    text-align: left;
    padding-left: 30px;
  }
  .timeline-centered > .timeline-item:nth-child(even) .timeline-info {
    float: right;
    text-align: left;
    padding-left: 30px;
  }
  .timeline-centered > .timeline-item:nth-child(even) .timeline-content {
    float: left;
    text-align: right;
    padding-right: 30px;
  }
  .timeline-centered > .timeline-item.period .timeline-content {
    float: none;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  .timeline-centered .timeline-item.period {
    padding: 50px 0 90px;
  }
  .timeline-centered .period .timeline-marker:after {
    height: 30px;
    bottom: 0;
    top: auto;
  }
  .timeline-centered .period .timeline-title {
    left: auto;
  }
}

/*----------------------------------------------
    MOD: MARKER OUTLINE
----------------------------------------------*/
.marker-outline .timeline-marker:before {
  background: transparent;
  border-color: #FF6B6B;
}
.marker-outline .timeline-item:hover .timeline-marker:before {
  background: #FF6B6B;
}


/*=======================================================
      Widgets / Shortcodes
========================================================*/

.light-tabs .nav-tabs {
    border-top: 1px solid #f1f1f1;
    border-bottom: 0;
    margin-bottom: 5px;
}
.light-tabs .nav-tabs>li {
    float: none;
    display: inline-block;
}

.light-tabs p {
	margin-top:20px;
	font-weight: 300;
}

.light-tabs .nav-tabs>li>a {
    margin-right: 0;
    font-size: 14px;
    line-height: 25px;
    color: #212121;
    border: 0;
    border-radius: 0;
	text-transform:uppercase;
	letter-spacing:2px;
}

.light-tabs .nav>li>a:focus, 
.light-tabs .nav>li>a:hover {
    background-color: transparent;
}

.light-tabs .nav-tabs>li.active>a, 
.light-tabs .nav-tabs>li.active>a:focus, 
.light-tabs .nav-tabs>li.active>a:hover {
    border: 0;
    color: #212121;
    position: relative;
	background:transparent;
}
.light-tabs .nav-tabs>li.active>a::before {
    content: "";
    background-color: #3949ab;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: -1px;
}

section.dark-block .light-tabs .nav-tabs>li.active>a::before {
	background-color: #212121;
}

.icon-tabs .nav-tabs {
    border-top: 0px solid #f1f1f1;
    border-bottom: 0;
    margin-bottom: 5px;
}
.icon-tabs .nav-tabs>li {
    float: none;
    display: inline-block;
}

.icon-tabs p {
	margin-top:20px;
}

.icon-tabs .nav-tabs>li>span {
  display: block;
    margin-right: 0;
    font-size: 14px;
    line-height: 25px;
    color: #212121;
    border: 0;
    border-radius: 0;
	text-transform:uppercase;
	letter-spacing:2px;
}

.icon-tabs .nav-tabs li > i {
  color: #212121;
}

.icon-tabs .nav>li>a:focus, 
.icon-tabs .nav>li>a:hover {
    background-color: transparent;
}

.icon-tabs .nav-tabs>li.react-tabs__tab--selected, 
.icon-tabs .nav-tabs>li.react-tabs__tab--selected > span,
.icon-tabs .nav-tabs>li.react-tabs__tab--selected > i {
    border: 0;
    color: #3949ab;
    position: relative;
	background:transparent;
}

.icon-tabs .nav-tabs>li> i {
    display: block;
    font-size: 30px;
    margin-bottom: 20px;
}

section.orange-block .nav-tabs > li > a i {
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
} 
section.orange-block .icon-tabs .nav-tabs>li.react-tabs__tab--selected>a, 
section.orange-block .icon-tabs .nav-tabs>li.react-tabs__tab--selected>a:focus, 
section.orange-block .icon-tabs .nav-tabs>li.react-tabs__tab--selected>a:hover {
  color: #ffab40;
}

section.orange-block .nav-tabs .react-tabs__tab {
  border:none;
  padding: 0;
} 

section.orange-block .nav-tabs > li > a {
  margin-right: 0;
    font-size: 14px;
    line-height: 25px;
    color: #212121;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
}

#services-onepage .icon-tabs .nav-tabs > li > a > i {
  display: block;
  font-size: 30px;
  margin-bottom: 20px;
}
#services-onepage .icon-tabs .nav-tabs > li > a {
  margin-right: 0;
    font-size: 14px;
    line-height: 25px;
    color: #212121;
    border: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
}
#services-onepage .icon-tabs .nav-tabs>li.react-tabs__tab {
  padding: 0;
}
#services-onepage .icon-tabs .nav-tabs>li.react-tabs__tab--selected>a, 
#services-onepage .icon-tabs .nav-tabs>li.react-tabs__tab--selected>a:focus, 
#services-onepage .icon-tabs .nav-tabs>li.react-tabs__tab--selected>a:hover {
  color: #3949ab;
  border: 0;
  position: relative;
  background: transparent;
}

/* ----------------------------------------
	Accordion Color
-------------------------------------------*/

.accordion-color .panel {
    box-shadow: none;
    border:0;
    border-radius: 0;
}

.accordion-color .panel .panel-heading {
    position: relative;
    padding: 0;
	border-bottom: 0px solid #fff;
}
.accordion-color .panel-title a.collapsed{
    display: block;
    color: #212121;
    background-color: #fff;
    border-color: #f1f1f1;
    padding: 22px 15px 22px 64px;
    border-radius: 0px;
}
.accordion-color .panel-title a{
    display: block;
    color: #fff;
    border-color: #f1f1f1;
    padding: 22px 15px 22px 64px;
    background-color: #3949ab;
	border-bottom: 1px solid #3949ab;
}
.accordion-color .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 1px solid #fff;
	background: #fff;
}
.accordion-color .panel .panel-heading a:after {
    font-family:"Ionicons";
    content: "\f415";
    font-size: 24px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
}
.accordion-color .panel .panel-heading a.collapsed:after {
    content: "\f374";
}


/* ----------------------------------------
	Accordion Transparent
-------------------------------------------*/

.accordion-transparent .panel {
    box-shadow: none;
    border:0;
    border-radius: 0;
	background-color:transparent;
}

.accordion-transparent .panel .panel-heading {
    position: relative;
    padding: 0;
    background-color: transparent;
    border-bottom: 1px solid #3949ab;
    border-top: 1px solid #3949ab;
    border-left: 1px solid #3949ab;
    border-right: 1px solid #3949ab;
    border-radius: 0;
}
.accordion-transparent .panel-title a.collapsed{
    display: block;
    color: #212121;
    background-color: transparent;
    border-color: transparent;
    padding: 22px 15px 22px 80px;
    border-radius: 0px;
}
.accordion-transparent .panel-title a{
    display: block;
    color: #212121;
    border-color: transparent;
    padding: 22px 15px 22px 80px;
    background-color: transparent;
}
.accordion-transparent .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 1px solid transparent;
    background: transparent;
    border-bottom: 1px solid #3949ab;
    border-left: 1px solid #3949ab;
    border-right: 1px solid #3949ab;
}
.accordion-transparent .panel .panel-heading a:after {
    font-family:"Ionicons";
    content: "\f462";
    font-size: 24px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
	border-right: 1px solid #3949ab;
}
.accordion-transparent .panel .panel-heading a.collapsed:after {
    content: "\f489";
}

section.dark-block .accordion-transparent .panel .panel-heading {
	border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
}

section.dark-block .accordion-transparent .panel .panel-heading a:after {
	border-right: 1px solid #fff;
	color: #fff;
}

section.dark-block .accordion-transparent .panel-default>.panel-heading+.panel-collapse>.panel-body {
	border-bottom: none;
    border-left: none;
    border-right: none;
}

section.dark-block .accordion-transparent .panel-title a.collapsed, section.dark-block .accordion-transparent .panel-title a {
	color:#fff;
}

.accordion-transparent > .rc-collapse-item > .rc-collapse-content {
  background-color: transparent !important;
}

.accordion-transparent > .rc-collapse-item > .rc-collapse-header {
  position: relative;
    padding: 0;
    background-color: transparent;
    border: 1px solid #3949ab;
    border-radius: 0;
    color: #212121 !important;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px !important;
    font-weight: 500;
    padding: 22px 15px 22px 80px;
    background-color: transparent;
}

.accordion-transparent > .rc-collapse-item-active {
  border-left: 1px solid #3949ab;
  border-right: 1px solid #3949ab;
  border-bottom: 1px solid #3949ab;
}

.accordion-transparent > .rc-collapse-item-active > .rc-collapse-header {
  border-left: none;
  border-right: none;
}

.accordion-transparent > .rc-collapse-item > .rc-collapse-header:focus,
.accordion-transparent > .rc-collapse-item > .rc-collapse-header:active {
  outline: 0;
}
.accordion-transparent.rc-collapse > .rc-collapse-item > .rc-collapse-header > i {
  display: none;
}


.accordion-transparent.rc-collapse > .rc-collapse-item > .rc-collapse-header:after {
  font-family:"Ionicons";
  content: "\f489";
    font-size: 24px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
	border-right: 1px solid #3949ab;
}

.accordion-transparent.rc-collapse > .rc-collapse-item-active > .rc-collapse-header:after {
  font-family:"Ionicons";
    content: "\f462";
    font-size: 24px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
	border-right: 1px solid #3949ab;
}

.dark-block .accordion-transparent.rc-collapse {
  background-color: transparent;
}

.dark-block .accordion-transparent.rc-collapse > .rc-collapse-item-active {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.dark-block .accordion-transparent > .rc-collapse-item > .rc-collapse-header {
    border: 1px solid #fff;
}

.dark-block .accordion-transparent.rc-collapse > .rc-collapse-item-active > .rc-collapse-header:after {
	border-right: 1px solid #fff;
}

.dark-block .accordion-transparent.rc-collapse > .rc-collapse-item > .rc-collapse-header:after  {
  border-right: 1px solid #fff;
}

.dark-block .accordion-transparent > .rc-collapse-item > .rc-collapse-header {
  color: #fff !important;
}

.dark-block .accordion-transparent > .rc-collapse-item .rc-collapse-content-box {
  color: #888
}


/* ----------------------------------------
	Flex Slider
-------------------------------------------*/

.slide-img-static {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slider-bg-static {
	border: 0;
    margin: 0;
    height: 500px;
    background-color: #222225;
}

.slider-bg-static .slides {
    height: 100%;
}

.slider-bg-static .slides > li {
    height: 100%;
}

.slider-bg-static .hero-text {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    width: 100%;
    z-index: 10;
    color: #fff;
}



/* ----------------------------------------
	Theme Icons
-------------------------------------------*/

.icon-examples{
    color: #888;
    margin-left: 1px;
    vertical-align: middle;
}
.icon-examples .icon-box{
    display: block;
    width: 33%;
    float: left;
    padding: 0;
    border: 1px solid #e5e5e5;
    margin: -1px 0 0 -1px;
    font-size: 13px;
}
@media only screen and (max-width: 1024px) {
    .icon-examples .icon-box{
        width: 50%;
    }
}
@media only screen and (max-width: 480px) {
    .icon-examples .icon-box{
        width: 100%;
    }
}
.icon-examples .icon-box:hover {
    background: #fff;
    color: #212121;
}
.icon-examples .icon-box > span {
    display: inline-block;
    margin-right: 15px;
    min-width: 60px;
    min-height: 60px;
    border-right: 1px solid #e5e5e5;
    line-height: 70px;
    text-align: center;
    font-size: 25px;
}

/* ----------------------------------------
	Restuarant 
-------------------------------------------*/
.circle-content-box {
	background-color:#fff;
	border-radius: 0px 50px 0px 50px;
    -moz-border-radius: 0px 50px 0px 50px;
    -o-border-radius: 0px 50px 0px 50px;
    -webkit-border-radius: 0px 50px 0px 50px;
}

.menu-text-content {
    margin-bottom: 20px;
}

.menu-text-content h3 {
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 10px;
	text-transform: uppercase;
}

.menu-text-content h6 {
    margin-top: 15px;
	line-height: 1.5;
}

.menu-text-content .label-default {
    background-color: #ffab40;
    text-transform: uppercase;
    font-weight: 400;
    padding: 5px 20px;
}

.service-content {
    margin-top: 50px;
    padding-bottom: 20px;
}

.service-content h3 {
	margin-top: 0;
    font-size: 18px;
	text-transform: uppercase;
}

.service-box {
    margin-bottom: 25px;
}

.service-box-info {
	background-color:#f5f5f5;
	padding: 40px;
}

.service-box-info h3 {
	font-size:20px;
	text-transform:uppercase;
}


section.orange-block .icon-tabs .nav-tabs>li.active>a, section.orange-block .icon-tabs .nav-tabs>li.active>a:focus, section.orange-block .icon-tabs .nav-tabs>li.active>a:hover {
	color: #ffab40;
}

.service-wrap {text-align:left;}

/* ----------------------------------------
	Online Shop
-------------------------------------------*/
.product {
    margin:0 0 30px 0;
}

.p0.product {
    margin: 0;
    padding: 0;
}
.product-wrap {
    position: relative;
    overflow: hidden;
}
.product-img img {
    width: 100%;
}
.product-wrap .product-caption {
    width: 100%;
    position: absolute;
    z-index: 2;
    height: 100%;
    opacity: 0;
	visibility:hidden;
    top: 0;
    background-color: rgba(0,0,0,0.9);
}
.product-wrap:hover .product-caption {
    opacity: 1;
	visibility:visible;
}
.product-description {
    width: 100%;
    height: 100%;
}
.product-description-wrap {
    top: 0%;
    padding: 25px;
    height: 100%;
}
.product-description .product-title, .product-description .product-subtitle {
    position: relative;
    left: 0;
    color: #fff;
    width: 100%;
    opacity: 0;
    visibility: hidden;
}



.product-description .product-title{
	top: 0;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.product-description .product-title a i{
	color:#fff;
}

.product-description .product-subtitle {
    top: 0;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.product-wrap:hover .product-description .product-title {
    opacity: 1;
	visibility: visible;
	top: 30px;
}

.product-wrap:hover .product-description .product-subtitle {
    opacity: 1;
	visibility: visible;
	top: 25px;
}

.product .product-detail {
	margin-top:0px;
}

.product .product-detail h4 {
	font-size: 14px;
	margin-top:0;
}

.product .product-detail P {
    color: #3949ab;
}

.old-price {
    text-decoration: line-through;
    color: #c1c1c1;
}

.product-slider.flexslider{
	background:transparent;
}

.product-slider .flex-control-thumbs {
    position: relative;
    bottom: auto;
    margin: 5px 0 0 0;
}

.product-slider .flex-control-thumbs > li {
    width: 25%;
    padding: 0 2px;
}


.product-slider .flex-control-thumbs > li img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.product-slider .flex-control-nav li {
	margin-bottom: 15px;
}

.product .product-wrap .badge-box {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    z-index: 4;
}

.product .product-wrap .badge-box .sale-box {
    position: absolute;
    left: 5px;
    right: auto;
    top: 5px;
    font-size: 14px;
    background-color: #9e9e9e;
}

.product .product-wrap .badge-box span {
    margin: 0;
    color: #fff;
    min-height: 14px;
    line-height: 20px;
    padding: 1px;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    min-width: 45px;
    height: auto;
    clear: both;
    font-weight: normal;
    text-align: center;
}

.product .product-wrap .badge-box .new-arrive {
    position: absolute;
    right: 5px;
    left: auto;
    top: 5px;
    font-size: 14px;
    background-color: #3949ab;
}

.single-product-des, .product-fabric-detail {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #dddfe6;
    border-bottom: 1px solid #dddfe6;
    margin-top: 40px;
}

.single-product-qty {
    padding-top: 20px;
    padding-bottom: 20px;
    float: left;
}

.single-product-qty .qty {
    position: relative;
    float: left;
    height: 48px;
    width: 70px;
    background: transparent!important;
    border: 1px solid #dddfe6;
    z-index: 1;
    text-align: center;
    padding: 0 10px;
}

.single-product-qty .input-group-btn {
  margin-left: 10px;
  display: inline-block;
}

.single-product-qty .input-group-btn.input-group-btn:last-child>.btn {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.product-fabric-detail {
    float: left;
    width: 100%;
    margin-top: 0;
	border-bottom: 0;
}

.product-btn-group li a {
	background-color:#eeeeee;
	color:#212121;
}

.product-btn-group li a:hover {
	background-color:#3949ab;
}

.product-detail .product-star-rating {
    padding: 5px 0;
}

.product-star-rating .product-stars {
    display: inline-block;
    position: relative;
}

.product-star-rating .product-stars-rating{
    display: block;
    position: relative;
    overflow: hidden;
}

.product-star-rating .product-stars:before {
    content: "\f3ae\f3ae\f3ae\f3ae\f3ae";
    font-family: "Ionicons";
    color: #d2d2d2;
    position: absolute;
    left: 0;
    right: 0;
}

.product-star-rating .product-stars-rating:before {
    content: "\f2fc\f2fc\f2fc\f2fc\f2fc";
    font-family: "Ionicons";
    color: #fdca6d;
}


.shop-cart {
  border-color: #ececec;
  margin-bottom: 50px;
  background:#fff;
}

.shop-cart > thead > tr > th {
  border-bottom: 1px solid #ececec;
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
  color: #212121;
  font-weight: 400;
  padding: 15px 20px;
  text-align: center;
  border: 1px solid #ececec;
}
.shop-cart > tbody > tr > td {
  padding: 30px 8px;
  vertical-align: middle;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  border-bottom: 1px solid;
  border-color: #ececec;
  text-align: center;
}
.shop-cart > tbody > tr > td.product-subtotal {
  color: #212121;
}
.shop-cart > tbody > tr > td.product-remove i {
  font-size: 20px;
}
.shop-cart > tbody > tr > td > a {
  color: #212121;
}
.shop-cart > tbody > tr > td > .form-control {
  width: 60px;
  padding: 0 12px;
  font-size: 13px;
  text-align: center;
  height: 45px;
  line-height: 43px;
  margin-left: auto;
  margin-right: auto;
}
.shop-cart > tbody > tr > td .cart-price {
  font-size: 14px;
}
.shop-cart > tbody > tr > td img {
  max-height: 100px;
  width: auto;
}
.shop-cart input.input-sm,
.shop-cart input.btn-sm {
  line-height: 35px;
  width: auto!important;
}
.shop-cart .coupon {
  float: left!important;
}

.shop-table th {
	font-weight:400;
	color:#212121;
}

.shop-table.table-bordered>thead>tr>th, .shop-table.table-bordered>tbody>tr>th, .shop-table.table-bordered>tfoot>tr>th, .shop-table.table-bordered>thead>tr>td, .shop-table.table-bordered>tbody>tr>td, .shop-table.table-bordered>tfoot>tr>td {
	border-bottom: 0;
}

.payment-box {
	background:#fff;
}

.form-coupon {
	padding-right: 0!important;
}
.form-coupon, .summary-cart {
    padding: 25px;
}

.summary-cart {
    border-left: 1px solid #ececec;
}

.form-coupon .btn, .form-domain .btn {
	height:60px;
}


.order_table {
  border-radius: 0;
  border: 0;
}
.order_table td {
  padding: 8px 12px;
}
.order_table thead th {
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
}
.order_table tbody th,
.order_table tbody td,
.order_table tfoot td,
.order_table tfoot th {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  border-top: 1px solid #ececec!important;
}
.order_table .shipping p {
  font-size: 14px;
  font-weight: 400;
}
form.checkout_coupon,
form.login,
form.register {
  border-radius: 0;
  border-color: #ececec;
}
.order-total strong {
  font-weight: 500;
}


.checkout-form {
    background: #fff;
    padding: 30px;
    box-shadow: 0 10px 30px 5px rgba(17,21,23,.1);
}

.checkout-form .form-group label {
	font-family: 'Poppins', sans-serif;
	font-size:13px;
	font-weight:400;
}

.checkout-form .form-group {
    float: left;
    width: 100%;
}

.checkout-form .form-control {
	padding: 20px;
	font-size:13px;
}

.checkout-form .form-select select, .form-select input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 20px;
}

.checkout-form .form-select:after {
    content: "\f3d0";
    font-family: "Ionicons";
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -12px;
    font-size: 20px;
}

.checkout-form .form-select {
    position: relative;
}


.banner-box {
	width: 100%;
    padding: 60px 40px;
    position: relative;
    overflow: hidden;
}

.banner-box .overlay-bg-dark {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}

.banner-box .blue-overlay-bg {
    background: rgba(33,150,243,0.82);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}





.product-slide .small-thumb.slick-slide {
  opacity: .7;
  margin-bottom: 15px;
  cursor: pointer;
}
.product-slide .small-thumb.slick-slide.slick-current {
  opacity: 1;
}

.product-slider .slick-prev , .product-slider .slick-next {
	font-size:30px;
}

.product-slider .slick-prev i, .product-slider .slick-next i{
    color: #212121;
	opacity:0.5;
}

.product-slider .slick-prev i:hover, .product-slider .slick-next i:hover{
    opacity:1;
}

.product-slider .slick-prev {
	left:10px;
}

.product-slider .slick-next {
	right:10px;
}

.star-rating {
  line-height:32px;
  font-size:1.25em;
}

.star-rating .ion-android-star{color: #ffcc80;}

/* ----------------------------------------
	App Landing
-------------------------------------------*/


.i-phones .img-responsive{
	width:auto;
}

.app-form .form-control:focus {
	border-color: #f44336;
}


.app-btn {
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
}

.error-message {
	color: #FF3333;
	font-family: Roboto;
	margin-top: 5px;
}

.success-message {
	color:#33cc33;
	font-family: Roboto;
	margin-top: 5px;
}

.app-bg h1{
	font-size:70px;
}

.app-btns a i {
    font-size: 16px;
}

#pricing h2.source-font {
	margin-top:120px;
}

/* ----------------------------------------
	Fitness
-------------------------------------------*/


.fitness-section h3 {
	margin-top:0;
	line-height:1.5em;
	color:#fff;
	font-size:26px;
}

.img-box {
	position:relative;
	transition: .3s;
	-webkit-transition: .3s;
	-ms-transition: .3s;
	-moz-transition: .3s;
}

.img-box:hover{
	position: relative;
    z-index: 10;
    transform: scale(1.05);
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	-moz-transform: scale(1.05);
}

.img-box .img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 35px;
}

.img-box .img-overlay.img-bg-red {
	background-color:rgba(239, 71, 58, 0.8);
}

.img-box .img-overlay.img-bg-purple {
	background-color:rgba(94, 53, 177, 0.8);
}

.img-box .img-overlay.img-bg-teal {
	background-color:rgba(29, 233, 182, 0.8);
}

.schedule-tabs .nav-tabs>li.react-tabs__tab {
  padding: 0;
  border: 0;
}

.schedule-tabs .nav-tabs>li.react-tabs__tab--selected>a, .schedule-tabs .nav-tabs>li.react-tabs__tab--selected>a:focus, .schedule-tabs .nav-tabs>li.react-tabs__tab--selected>a:hover {
  border: 0;
  color: #fff;
  position: relative;
  background-color:#212121;
}

.schedule-tabs .nav-tabs>li.active>a, .schedule-tabs .nav-tabs>li.active>a:focus, .schedule-tabs .nav-tabs>li.active>a:hover {
    border: 0;
    color: #fff;
    position: relative;
    background-color:#212121;
}

.schedule-tabs .nav-tabs>li {
    float: none;
    display: inline-block;
	margin-right: -3px;
}

.schedule-tabs .nav-tabs>li>a {
    margin-right: 0;
    font-size: 14px;
    line-height: 25px;
    color: #212121;
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: #eee;
	padding: 10px 39px;
}

.schedule-tabs .nav-tabs {
    border: none;
    margin-bottom: 30px;
}

.schedule-menu {
	padding-left:0;
	list-style: none;
    margin: 0;
    text-align: left;
}

.schedule-menu li h4 {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    position: relative;
    font-weight: 500;
}

.schedule-menu li {
    padding: 20px 0;
    overflow: hidden;
    border-bottom: 1px solid #eee;
    margin: 15px 0;
}

.schedule-menu li .schedule-contect {
    padding-left: 15px;
    padding-right: 15px;
}

.schedule-title {
    text-align: left;
}

.schedule-time {
    text-align: right;
	float: right;
}

.contact-us .section-heading {
    text-align: left;
    margin: 0;
}



.product .preview-box {
	background:#212121;
	margin-top:0;
	padding: 15px;
}

.preview-box h4 {
    color: #fff;
}



.terms h2 {
	position: relative;
    font-size: 30px;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.terms h2:after {
	content: '';
    position: absolute;
    top: 100%;
    margin-top: 10px;
    left: 0;
    width: 80px;
    border-top: 4px solid #212121;
}

.terms p {
	margin-bottom:25px;
}

.careers-listing {
	list-style: none; 
	padding-left:0;
}
.careers-listing h5 {
	font-size:16px;
	color:#3949ab;
	margin-top: 10px;
}
.careers-listing li { 
	padding: 25px 0;  
	border:none;  
	border-bottom: 1px solid #e8e8e8; 
	list-style:none; 
}

.career-main span, .career-category span {
    font-weight: 300;
}

.career-location { 
	border-left: solid 1px #ccc;  
	padding-left: 13px;
}
.career-type {
	margin-right:10px;  
}
.career-details {
	text-align: right; 
	padding-top:10px;
}
.career-category { 
	padding-top:14px;
}

.animated-icon {
    width: 50px;
    position: absolute;
    left: 0;
}

.collapsing{
	-webkit-transition-duration:.30s;
	-o-transition-duration:.30s;
	transition-duration:.30s;
}




html.fp-enabled,
.fp-enabled body {
    margin: 0;
    padding: 0;
    overflow:hidden;

    /*Avoid flicker on slides transitions for mobile phones #336 */
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
#superContainer {
    height: 100%;
    position: relative;

    /* Touch detection for Windows 8 */
    -ms-touch-action: none;

    /* IE 11 on Windows Phone 8.1*/
    touch-action: none;
}
.fp-section {
    position: relative;
    -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
    -moz-box-sizing: border-box; /* <=28 */
    box-sizing: border-box;
}

.fp-slide {
    float: left;
}
.fp-slide, .fp-slidesContainer {
    height: 100%;
    display: block;
}
.fp-slides {
    z-index:1;
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-out; /* Safari<=6 Android<=4.3 */
    transition: all 0.3s ease-out;
}
.fp-section.fp-table, .fp-slide.fp-table {
    display: table;
    table-layout:fixed;
    width: 100%;
}
.fp-tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.fp-slidesContainer {
    float: left;
    position: relative;
}
.fp-controlArrow {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    position: absolute;
    z-index: 4;
    top: 50%;
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    margin-top: -38px;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}
.fp-controlArrow.fp-prev {
    left: 15px;
    width: 0;
    border-width: 38.5px 34px 38.5px 0;
    border-color: transparent #fff transparent transparent;
}
.fp-controlArrow.fp-next {
    right: 15px;
    border-width: 38.5px 0 38.5px 34px;
    border-color: transparent transparent transparent #fff;
}
.fp-scrollable {
    overflow: hidden;
    position: relative;
}
.fp-scroller{
    overflow: hidden;
}
.iScrollIndicator{
    border: 0 !important;
}
.fp-notransition {
    -webkit-transition: none !important;
    transition: none !important;
}
#fp-nav {
    position: fixed;
    z-index: 100;
    margin-top: -32px;
    top: 50%;
    opacity: 1;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
}
#fp-nav.right {
    right: 17px;
}
#fp-nav.left {
    left: 17px;
}
.fp-slidesNav{
    position: absolute;
    z-index: 4;
    left: 50%;
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}
.fp-slidesNav.bottom {
    bottom: 17px;
}
.fp-slidesNav.top {
    top: 17px;
}
#fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
}
#fp-nav ul li,
.fp-slidesNav ul li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position:relative;
}
.fp-slidesNav ul li {
    display: inline-block;
}
#fp-nav ul li a,
.fp-slidesNav ul li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span{
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%;
 }
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 4px;
    width: 4px;
    border: 0;
    background: #fff;
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -2px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span{
    width: 10px;
    height: 10px;
    margin: -5px 0px 0px -5px;
}
#fp-nav ul li .fp-tooltip {
    position: absolute;
    top: -2px;
    color: #fff;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}
#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active + .fp-tooltip {
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    width: auto;
    opacity: 1;
}
#fp-nav ul li .fp-tooltip.right {
    right: 20px;
}
#fp-nav ul li .fp-tooltip.left {
    left: 20px;
}
.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell{
    height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
    height: auto !important;
}



.model-box {
    position: absolute;
    top: 50px;
    right: -140px;
    padding: 20px 30px;
    background: #fff;
}

.model-box h4 {
    margin-bottom: 10px;
}

.item-model {
	margin-top:30px;
}

.grandy {
	font-size:80px;
	color:#212121;
	text-transform:uppercase;
	font-weight:700;
}

.demo-section p {
	color: #525252;
	font-size: 18px;
    line-height: 1.5em;
    margin-top: 25px;
	font-family: 'Poppins', sans-serif;
}

.demo-box {margin-bottom:25px;text-align:center;}

.demo-box a {
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.08), 0px 6px 18px 0px rgba(0, 0, 0, 0.05);
    transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(0px);
}

.demo-box a:hover {
    box-shadow: 0px 0px 114px 0px rgba(0, 0, 0, 0.08), 0px 30px 25px 0px rgba(0, 0, 0, 0.05);
    transform: translateY(-10px);
}

.demo-box img {
    border-radius: 4px;
    box-shadow: 0 10px 30px 0 rgba(31,35,37,.1);
    transition: all .35s cubic-bezier(0.4,0,.2,1);
}

.no-radius img {
	border-radius:0;
}

.single-img {
	display: inline-block;
    vertical-align: top;
    max-width: 100%;
}

.demo-box h3 {
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.15em;
}

/* Clients Slider */

.client-slider .slick-slide {
  margin: 0 15px;
}

.client-slider .slick-track {
  display: flex;
}
